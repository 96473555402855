@media only screen and (min-width: 40em) {
    .version-info{
        position: fixed;
        bottom:1em;
        right:1em;
        display:inline-block;
        border:1px solid transparent;
        padding: 0.5em 1em;
        border-radius:10px;
        font-size: 0.85em;
        text-align: left;
        background: #e5e5e5;
        cursor: pointer;
    }
}
@media only screen and (max-width: 39em) {
    .version-info{
        display:none;
    }
}
.version-info:hover,
.version-info-opened{
    border:1px solid rgba(0,0,0,0.75);
}
.version-info h3{
    font-size: 1.1em;
    font-weight: 400;
}
.version-info-opened .menu{
    display:block !important;
}
.version-info .menu{
    display:none;
    position: absolute;
    bottom:3em;
    right:0;
    width:170px;
    height: auto;
    background: #fff;
    box-sizing: border-box;
    border-radius: 10px;
    filter: drop-shadow(0 0 0.25rem rgba(0,0,0,0.15));
    padding:0.3em;
    border:1px solid rgba(0,0,0,0.25);
    transition: all 0.5s;
    cursor:default;
}
.version-info .menu::before {
    content: "";
    position: absolute;
    bottom: -10px;
    right:15%;
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 10px solid rgba(0,0,0,0.25);
}
.version-info .menu::after {
    content: "";
    position: absolute;
    bottom: -9px;
    right:15%;
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 10px solid #fff;
}
.version-info .menu li{
    padding:0.9em 1em 0.9em 1em;
    border-bottom:1px solid rgba(0,0,0,0.15);
    box-sizing: border-box;
    display: block;
}

.version-info .menu li:last-child{
    border-bottom:1px solid rgba(0,0,0,0);
}
.version-info .menu a{
    display:block;
    position: relative;
    text-decoration: none;
    color:#202020;
    padding:0.9em 1em 0.9em 3em;
    box-sizing: border-box;
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: 1em center;
    background-size: 15px;
}
.version-info .menu a:hover{
    background-color: rgba(0,0,0,0.1);
}