.login-container{
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    display:block;
	min-height: 100vh;
	background: url("../../img/dots.svg");
	background-size: 100%;
}
@media only screen and (min-width: 40em) {
	.login-container{
		animation: bgmovement 360s linear infinite;
	}
}
@keyframes bgmovement {
	0%{
		background-position: 0 0;
	}
	100%{
		background-position: -10000px 0;
	}
}
@media only screen and (min-width: 40em) {
	.login-left{
		position: absolute;
		top:0;
		left:0;
		width:50%;
		display:block;
		background:transparent;
		box-sizing: border-box;
		padding: 0;
		animation: zoomInI 0.5s;
	}
	.login-right{
		position: absolute;
		top:0;
		right:0;
		width:50%;
		height: auto;
		background:transparent;
		box-sizing: border-box;
		padding: 3em 0 0 3em;
		text-align: left;
	}
	.login-page{
		position: absolute;
		top: 18vh;
		left:50%;
		transform: translate(-50%,0);
		max-width:750px;
		width:100%;
		height: 400px;
		line-height: 1.5;
		display: block;
		text-align: center;
	}
}
@media only screen and (max-width: 39em) {
	.login-left{

	}
	.login-right{
		margin: 2.5em 0 0 0;
		padding: 2.5em 2em 0 2em;
		text-align: center;
		box-sizing: border-box;
		position: relative;
	}
	.login-right:after{
		content: '';
		position: absolute;
		top:0;
		left:50%;
		transform: translate(-50%,0);
		width: 100%;
		max-width: 40vw;
		height: 1px;
		border-top:1px solid rgba(0,0,0,0.3);
	}
	.login-page{
		line-height: 1.5;
		display: block;
		text-align: center;
		position: relative !important;
		max-width: 400px !important;
		margin: 18vh auto;
	}
}
.login-right h2{
	font-weight: 600;
	font-size: 2em;
	color:rgba(0,0,0,0.8);
	line-height: 1.3;
}
.login-right h3{
	margin: 1em 0 0 0;
	font-weight: 400;
	font-size: 1.3em;
	color:rgba(0,0,0,0.6);
	line-height: 1.3;
}

.login-left:after{
	content:'';
	position: absolute;
	top:-3.5em;
	left:50%;
	transform: translate(-50%,0);
	width: 100%;
	height:2.5em;
	background: url("../../img/futrou.svg") center top no-repeat;
	background-size: auto 2em;
}
.login-page #login {
    position: relative;
    display:block;
	width:100%;
	box-sizing: border-box;
	border-radius: 15px;
	z-index: 100;
	box-shadow: 0 3px 12px rgba(0, 0, 0, 0.03), 0 3px 12px rgba(0, 0, 0, 0.06);
	border-top:2px solid #fbc531;
	overflow: hidden;
	background: #fff url("../../img/unlocked.svg") left 30px top 35px no-repeat;
	background-size: 40px;
	padding: 90px 35px 35px 35px;
	text-align: left;
}

.login-page #login h2 {
	font-size: 29px;
	font-weight: 300;
	line-height: 1.3;
	text-align: left;
	margin-bottom: 15px;
	color: rgba(0,0,0,0.7);
}
.login-page #login input[type="text"] {
    background: rgba(0,0,0,0.1) url("../../img/user.svg") no-repeat scroll 17px 16px;
    background-size: 17px;
}

.login-page #login input[type="password"] {
    background: rgba(0,0,0,0.1) url("../../img/password.svg") no-repeat scroll 17px 15px;
    background-size: 18px;
}

.login-page #login input[type="text"],
.login-page #login input[type="password"] {
	padding: 5px 20px 5px 48px;
	border-radius: 8px;
	border: 1px solid transparent;
	margin: 1em 0 0 0;
	color: #202020;
	font-size: 17px;
	font-weight: 400;
	width: 100%;
	height: 52px;
	box-sizing: border-box;
	outline-style: none;
	box-shadow: none;
}
.login-page #login input[type="text"]:focus,
.login-page #login input[type="text"].focus,
.login-page #login input[type="password"]:focus,
.login-page #login input[type="password"].focus {
	outline: 0;
	color: #000;
}

.login-page #login input[type="submit"] {
	padding: 12px 0 12px 0;
	border-radius: 25px;
	background: #fff right 0.25em center no-repeat;
	border: 1px solid #202020;
	color: #202020;
	font-size: 17px;
	font-weight: 400;
	display: block;
	cursor: pointer;
	transition: background 0.65s, color 0.65s;
	width: 100%;
	height: 52px;
    box-sizing: border-box;
    margin: 1.5em 0 0 0;
}
.login-page #login input[type="submit"]:hover {
	background: #37383a right 0.25em center no-repeat;
	transition: background 0.65s, color 0.65s;
	color: #fff;
}
.login-page #login input[type="submit"]:focus,
.login-page #login input[type="submit"].focus {
	outline: 0;
}

.submit-loader{
	background: #37383a url("../../img/loader-white.svg") right 0.25em center no-repeat !important;
	color: rgba(255,255,255,0.5) !important;
}
.login-page .bottom{
    text-align: center;
    padding: 1.25em 0 0 0;
}

.login-page .bottom a{
    text-decoration: none;
    margin: 0 0 0 0;
    color: rgba(0,0,0,0.4);
    display:inline-block;
    border:1px solid rgba(0,0,0,0);
    border-radius:1.5em;
	padding: 11px 2.5em 11px 1.5em;
	position: relative;
}
.login-page .bottom a:after{
	content:'';
	position: absolute;
	top:50%;
	transform: translate(0,-50%);
	right:10px;
	height: 25px;
	width: 25px;
	background: url("../../img/right-arrow.svg") center center no-repeat;
	background-size: auto 15px;
	display:block;
	opacity:0.5;
}
.login-page .bottom a:nth-child(2){
    margin: 0 0 0 1em;
}

.login-page .bottom a:hover{
    border:1px solid rgba(0,0,0,0);
	background:rgba(0,0,0,0.1);
    color:rgba(0,0,0,0.8);
}


.login-page .error {
    text-align: center;
	position: relative;
	padding: 15px 20px 15px 20px;
	border-radius: 10px;
	font-size: 16px;
	width: 100%;
    background:rgba(192, 57, 43, 0.15);
    border:1px solid rgba(192, 57, 43, 1);
	line-height: 1.2;
	color: #902b20;
    box-sizing: border-box;
    animation: flipInX 0.5s;
	transform: scaleY(1);
	transform-origin: bottom;
}
.login-page .error::after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 50%;
	transform: translate(-50%);
	width: 0;
	height: 0;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	border-top: 10px solid rgba(192, 57, 43,1.0);
}
.login-page .info {
	text-align: center;
	position: relative;
	padding: 15px 20px 15px 20px;
	border-radius: 10px;
	font-size: 16px;
	width: 100%;
	background:rgba(251, 204, 74, 0.2);
	border:1px solid rgba(0,0,0,0);
	line-height: 1.2;
	color: #000;
	box-sizing: border-box;
	animation: flipInX 0.5s;
	transform: scaleY(1);
	transform-origin: bottom;
}
.login-page .info::after {
	content: "";
	position: absolute;
	bottom: -10px;
	left: 50%;
	transform: translate(-50%);
	width: 0;
	height: 0;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	border-top: 10px solid #fef5db;
}

.login-button {
	padding: 11px 1.5em;
	border-radius: 25px;
	background: transparent;
	border: 1px solid #202020;
	color: #202020;
	font-size: 15px;
	font-weight: 400;
	display: inline-block;
	cursor: pointer;
	height: 45px;
	box-sizing: border-box;
	margin: 1.5em 0 0 0;
	text-decoration: none;
	transition: all 0.3s linear;
}
.login-button:hover {
	background: #37383a right 0.25em center no-repeat;
	color: #fff;
}
.login-button-now {
	background: url("../../img/right-arrow.svg") right 0.7em center no-repeat;
	background-size: auto 15px;
	padding: 11px 2.5em 11px 1.5em;
}
.login-button-now:hover{
	background: rgba(0,0,0,0.15) url("../../img/right-arrow.svg") right 0.7em center no-repeat;
	background-size: auto 15px;
	border:1px solid transparent;
	color:#000;
}
@keyframes flipInX {
    from {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
      opacity: 0;
    }
  
    40% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
      -webkit-animation-timing-function: ease-in;
      animation-timing-function: ease-in;
    }
  
    60% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
      opacity: 1;
    }
  
    80% {
      -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
      transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
    }
  
    to {
      -webkit-transform: perspective(400px);
      transform: perspective(400px);
    }
  }

@keyframes flipOutX {
	from {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
		opacity: 0;
	}

	40% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
		-webkit-animation-timing-function: ease-in;
		animation-timing-function: ease-in;
	}

	60% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
		opacity: 1;
	}

	80% {
		-webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
		transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
	}

	to {
		-webkit-transform: perspective(400px);
		transform: perspective(400px);
	}
}

@-webkit-keyframes zoomInI {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.9, 0.9, 0.9);
		transform: scale3d(0.9, 0.9, 0.9);
	}

	50% {
		opacity: 1;
	}
}
@keyframes zoomInI {
	from {
		opacity: 0;
		-webkit-transform: scale3d(0.9, 0.9, 0.9);
		transform: scale3d(0.9, 0.9, 0.9);
	}

	50% {
		opacity: 1;
	}
}
