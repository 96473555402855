
.frame_table{
    width:100%;
    margin: 1.8em 0 1em 0;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    border:1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
    overflow: hidden;
    background: #fff;
    text-align: left;
}

.frame_table table{
    width:100%;
}
.frame_table tr{
    width:100%;
    border-bottom:1px solid rgba(0,0,0,0.1);
    padding: 0.5em 1.5em 0.5em 1.5em;
    text-decoration: none !important;
    outline: none;
    color:#202020;
}
.frame_table tr:hover{
    background: rgba(0,0,0, 0.05);
}
.frame_table th{
    padding: 0.8em 2em 0.6em 2em;
    background: #fff;
    font-size: 0.9em;
    border-bottom:1px solid rgba(0,0,0,0.1);
}
.frame_table td{
    padding: 0.8em 1.5em 0.8em 2em;
}
.frame_table td:first-child i{
    font-weight: 600;
}
.frame_table th:nth-child(3),
.frame_table td:nth-child(3){
    text-align: right;
}
.frame_table .green,
.frame_table .red{
    font-size: 1.15em;
}
.frame_table .green{
    color:#27ae60;
}
.frame_table .red{
    color:#c0392b;
}