
@media only screen and (min-width: 40em) {
    .limit,
    .device,
    .serverlet_content .info-box{
        width:calc(100% - 0em);
    }
}
@media only screen and (max-width: 39em) {
    .limit,
    .device,
    .serverlet_content .info-box{
        width:100%;
    }
}
progress[value] {
    -moz-appearance: none;
    appearance: none;
    background-size: 35px 20px, 100% 100%, 100% 100%;
    color:red;
}
.devices{
    transition: all 0.5s linear;
}
.device{
    position: relative;
    display: block;
    background: #fff;
    border:1px solid rgba(0,0,0,0.2);
    margin: 0 0 0.75em 0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 0.6em 1em 0.6em 5.2em;
    line-height: 1.3;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    transition: all 0.5s linear;
}
.device:after{
    content:'';
    position: absolute;
    left:1em;
    top:1em;
    width:50px;
    height: 50px;
    border-radius:50%;
    border:1px solid rgba(0,0,0,0);
    background-color: rgba(0,0,0,0.1);
    background-image: url("../img/device/desktop.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 60%;
    display:block;
}
.device[data-device="mobile"]:after{
    background-image: url("../img/device/mobile.svg");
}
.device[data-device="tablet"]:after{
    background-image: url("../img/device/tablet.svg");
}
.device .os:after,
.device .browser:after{
    content:'';
    position: absolute;
    bottom:13px;
    width:16px;
    height: 16px;
    background-color: rgba(0,0,0,0);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    display:block;
}
.device .os:after{
    left:20px;
}
.device .browser:after{
    left:45px;
}
.device .os[data-os="Windows"]:after{
    background-image: url("../img/windows.svg");
}
.device .os[data-os="Mac OS"]:after{
    background-image: url("../img/device/apple.svg");
}
.device .os[data-os="Android"]:after{
    background-image: url("../img/device/android.svg");
}
.device .os[data-os="iOS"]:after{
    background-image: url("../img/device/apple.svg");
}
.device .os[data-os="Chromium OS"]:after{
    background-image: url("../img/device/chromeos.svg");
}
.device .os[data-os="Linux"]:after{
    background-image: url("../img/device/linux.svg");
}
.device .os[data-os="Ubuntu"]:after{
    background-image: url("../img/device/ubuntu.svg");
}
.device .os[data-os="Debian"]:after{
    background-image: url("../img/device/debian.svg");
}
.device .os[data-os="Fedora"]:after{
    background-image: url("../img/device/fedora.svg");
}
.device .os[data-os="Mint"]:after{
    background-image: url("../img/device/mint.svg");
}
.device .os[data-os="RedHat"]:after{
    background-image: url("../img/device/redhat.svg");
}
.device .os[data-os="FreeBSD"]:after{
    background-image: url("../img/device/freebsd.svg");
}
.device .os[data-os="Gentoo"]:after{
    background-image: url("../img/device/gentoo.svg");
}

.device .browser[data-browser="Firefox"]:after{
    background-image: url("../img/device/firefox.svg");
}
.device .browser[data-browser="Chrome"]:after{
    background-image: url("../img/device/chrome.svg");
}
.device .browser[data-browser="Opera"]:after{
    background-image: url("../img/device/opera.svg");
}
.device .browser[data-browser="Safari"]:after{
    background-image: url("../img/device/safari.svg");
}
.device .browser[data-browser="Edge"]:after{
    background-image: url("../img/device/edge.svg");
}
.device .browser[data-browser="IE"]:after{
    background-image: url("../img/device/internetexplorer.svg");
}
.device .browser[data-browser="Palemoon"]:after{
    background-image: url("../img/device/palemoon.svg");
}
.device .browser[data-browser="SeaMonkey"]:after{
    background-image: url("../img/device/seamonkey.svg");
}
.device .browser[data-browser="Vivaldi"]:after{
    background-image: url("../img/device/vivaldi.svg");
}
.device .browser[data-browser="Waterfox"]:after{
    background-image: url("../img/device/waterfox.png");
}
.device .browser[data-browser="Brave"]:after{
    background-image: url("../img/device/brave.svg");
}
.device .this_device{
    position: absolute;
    top:1em;
    right:1em;
    border:1px solid rgba(39, 174, 96,1);
    color:#27ae60;
    border-radius: 1em;
    padding:0.35em 0.75em;
    font-size: 0.8em;
}
.device .os {
    display: inline-block;
    font-size: 1.1em;
    color: rgba(0,0,0,0.9);
    margin: 0 0 0 0;
}
.device .browser {
    display: inline-block;
    font-size: 0.9em;
    color: rgba(0,0,0,0.9);
    margin: 0 0 0.5em 0;
}
.device .created {
    white-space: nowrap;
    display: block;
    font-size: 0.85em;
}
.device .ip {
    white-space: wrap;
    display: block;
    font-size: 0.85em;
}

.limit{
    position: relative;
    display: block;
    background: #fff;
    border:1px solid rgba(0,0,0,0.2);
    margin: 0 0 0.75em 0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 1em 5.5em 1em 7em;
    line-height: 1.3;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    transition: all 0.5s linear;
    overflow: visible;
}
.limit .title{
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
    left:1em;
}
.limit .label{
    position: absolute;
    display:block;
    top:50%;
    transform: translate(0,-50%);
    right:1em;
}
.limit progress {
    width: 100%;
    display: inline-block;
    padding: 0px;
    background: rgba(0,0,0,0.05);
    border:1px solid rgba(0,0,0,0.1);
    border-radius: 14px;
    overflow: hidden;
}
.limit progress[value]::-webkit-progress-bar{
    background: rgba(0,0,0,0.05);
    padding: 0 1px 1px 1px;
}

.limit progress[value]::-webkit-progress-value{
    border-radius: 12px;
    background: #fbc531;
}
.limit progress::-moz-progress-bar {
    border-radius: 12px;
    background: #fbc531;
}

.serverlet_content p a{
    text-decoration: none;
    color:#a47a04;
}
.serverlet_content p a:hover{
    color:#202020;
}

.serverlet_content .info-box h2{
    font-weight: 600;
    font-size: 1.1em;
    margin: 0 0 0.2em 0;
    color: rgba(0,0,0,0.8);
    letter-spacing: 1px;
}
.serverlet_content .info-box{
    position: relative;
    display: block;
    background: #fff;
    border:1px solid rgba(0,0,0,0.2);
    margin: 0 0 0.75em 0;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 1em 1.5em 1em 1.5em;
    line-height: 1.4;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    transition: all 0.5s linear;
    overflow: visible;
    min-height: 0;
}
.serverlet_content .info-box p:last-child{
    margin: 0;
}
.changelog .item:first-child{
    margin: 1em 0 0 0;
}
.changelog .item{
    display:block;
    margin: 0.25em 0 0 0;
    line-height: 1.5;
    position: relative;
    padding: 0 0 0 4em;
}
.changelog .item .type{
    font-size: 0.8em;
    border-radius: 10px;
    padding: 0em 0.5em;
    margin: 0 1em 0 0;
    position: absolute;
    display: inline-block;
    left:0;
    top:0.25em;
}
.changelog .item .bugfix{
    background: rgba(251, 197, 49, 0.15);
    border:1px solid #dba204;
    color:#af8103;
}
.changelog .item .new,
.changelog .item .change{
    background: rgba(76, 133, 213, 0.15);
    border:1px solid #0051c3;
    color:#0051c3;
}