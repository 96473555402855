footer{
	display:block;
	min-height: 50px;
	width: 100%;
	position: sticky;
	border-top:1px solid rgba(0,0,0,0.1);
	background: #f9f6f5;
}
footer .out-content{
	border-bottom:1px solid rgba(0,0,0,0.05);
}
footer .inner-content{
	position: relative;
	width:100%;
	max-width:800px;
	margin: auto;
	padding: 1.6em 0;
	text-align: center;
}
footer nav{
	display:flex;
	justify-content: space-between;
	text-align: left;
}
footer nav a{
	display:inline-block;
	text-decoration: none;
	color:rgba(0,0,0,0.5);
	font-size: 1.1em;
	font-weight:300;
	padding: 3px 0;
	margin: 0 0 .5em 0;
	line-height: 1.2;
}
footer nav a:hover{
	color:#202020;
}
