@media only screen and (min-width: 40em) {
    .content{
        position: relative;
        width:calc(100% - 250px);
        min-height: 100vh;
        margin: 0 0 0 250px;
        box-sizing: border-box;
        padding: 1em 2em;
        display:block;
        vertical-align: top;
        border-right: 1px solid rgba(0,0,0,0.15);
    }
    .mobile-only{
        display:none !important;
    }
}
@media only screen and (max-width: 39em) {
    .content{
        position: relative;
        width:100%;
        min-height: 100vh;
        margin: 0;
        box-sizing: border-box;
        padding: 1em 0.5em;
        display:block;
        vertical-align: top;
        border-right: 1px solid rgba(0,0,0,0.15);
    }
    .desktop-only{
        display:none !important;
    }
}
.nopadding{
    padding: 0 !important;
}
.white{
    background: #fbfbfb !important;
}
.content-small{
    width:calc(100% - 500px) !important;
}

@media only screen and (min-width: 40em) {
    .content .section-half{
        position: relative;
        margin: 1em;
        width:calc(50% - 2em);
        height: auto;
        box-sizing: border-box;
        display:inline-block;
    }
    .content .section-half:nth-child(2n){
        margin: 1em;
    }
    .content .section{
        position: relative;
        margin: 1em 0 0.5em 0;
        padding: 0 0.5em;
        width:100%;
        height: auto;
        min-height: 5em;
        box-sizing: border-box;
        display:inline-block;
        vertical-align: top;
    }
}
@media only screen and (max-width: 39em) {
    .content .section-half{
        position: relative;
        margin: 1em;
        width:calc(100% - 1.7em);
        height: auto;
        box-sizing: border-box;
        display:inline-block;
    }
    .content .section{
        position: relative;
        margin: 1em 1em 0.5em 1em;
        width:calc(100% - 2em);
        height: auto;
        min-height: 5em;
        box-sizing: border-box;
        display:inline-block;
        vertical-align: top;
    }
}
.content h1{
    font-weight: 300;
    font-size: 1.7em;
    display:inline-block;
    vertical-align: middle;
    margin: 0.8em 0 0.25em 15px;
}
.content .section-half h1,
.content .section h1{
    font-weight: 400;
    font-size: 1.3em;
    display:inline-block;
    vertical-align: middle;
    margin: 0.15em 0 0 0;
}
.content .section-half h2,
.content .section h2{
    font-weight: 400;
    font-size: 1.1em;
    display:inline-block;
    width: 100%;
    vertical-align: middle;
    margin: 1em 0 0 0;
}
.content .section-half .padded,
.content .section .padded{
}
.content .section-half .count,
.content .section .count{
    position: relative;
    left:0;
    top:0;
    font-weight: 400;
    font-size: 1em;
    display:inline-block;
    background:#dfdfdf;
    border:1px solid rgba(0,0,0,0);
    border-radius: 6px;
    overflow: hidden;
    padding: 0.25em 0.5em;
    box-sizing: border-box;
    margin: 0 1em 0 0;
    float:left;
}
.content .section-half .inner,
.content .section .inner{
    margin: 1em 0 0 0;
    border-radius: 9px;
    background: rgba(255,255,255,1);
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    border:1px solid rgba(0,0,0,0.1);
    box-sizing: border-box;
    width: 100%;
    min-height: 8.5em;
    display: block;
    overflow: hidden;
}

.content .section-half .inner-frame,
.content .section .inner-frame{
    margin: 1em 0 0 0;
    padding: 0.5em 0;
    border-radius: 9px;
    box-sizing: border-box;
    width: 100%;
    min-height: 1em;
    display: block;
    position: relative;
}
.content .section-half .inner-frame-blank,
.content .section .inner-frame-blank{
    min-height: 8.5em;
}

.content .section-half .inner-frame .next:hover,
.content .section .inner-frame .next:hover{
    background: #9c9c9c url("../img/right-arrow.svg") center center no-repeat;
    background-size: 40%;
}

.carousel .hbox-link:last-of-type{
    margin: 0 2em 0 0;
}
.content .box{
    width: 8em;
    height: 10em;
    display:inline-block;
    background: rgba(255,255,255,1);
    border:1px solid rgba(0,0,0,0.1);
    box-sizing: border-box;
    border-radius: 15px;
    margin: 2px 1em 1.2em 2px;
    overflow: visible;
    position: relative;
    text-align: center;
    z-index: 100;
    transform: scale(1);
    transition: transform 0.3s ease-out;
    cursor: pointer;
    color:#202020;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
}
.content .box:hover:after{
    content:'';
    width: 100%;
    height: 100%;
    border:2px solid #fbc531;
    position: absolute;
    top:-2px;
    left:-2px;
    display:block;
    border-radius: 15px;
}
.content .box:hover{
    background: #fff;
    box-shadow: 0 0 2px rgba(0,0,0,0.1);
    border:1px solid #fbc531;
}
.content .box .title{
    display:block;
    margin: 0.9em 0 0.25em 0;
    font-size: 1.8em;
    font-weight: 600;
    color:rgba(0,0,0,0.8);
    position: relative;
}
.content .box .params{
    position: relative;
    display:inline-block;
    font-size: 0.7em;
    font-weight: 400;
    margin: 0em 0 0em 0;
    width: 100%;
    padding: 0.8em 3em 1em 3em;
    white-space: break-spaces;
    line-height: 1.3;
    #background-image: url("../img/cpu.svg"), url("../img/ram.svg"), url("../img/ssd.svg");
    #background-position: left 0.5em top 1em, left 0.5em top 2.3em, left 0.5em top 3.5em;
    #background-repeat: no-repeat, no-repeat, no-repeat;
    #background-size: 1.2em, 1.2em, 1.2em;
    overflow: visible;
    box-sizing: border-box;
 }
.content .box .price{
    display:block;
    margin: 0em 0 0 0;
    font-size: 0.9em;
    font-weight: 600;
    z-index:200;
    position: relative;
    color:rgba(0,0,0,0.6);
    border-top:1px solid rgba(0,0,0,0.1);
    padding:0.6em 0 0.9em 0;
    background: rgba(0,0,0,0.01);
}


.content .monthly{
    font-weight: 400;
    font-size: 1.6em;
    padding:1.3em 0 0 40px;
    box-sizing: border-box;
    position: relative;
    z-index:100;
}
.content .monthly-green{
    color:#208e4e;
}
.content .monthly-red{
    color:#c0392b;
}
.content .monthly:after{
    content:'';
    position: absolute;
    right:40px;
    top:25px;
    width: 35px;
    height: 35px;
    background: url("../img/money.svg") center center no-repeat;
    background-size:100%;
    display: block;
    z-index:100;
    opacity:0.6;
}
.content .monthly-status{
    z-index:110;
    font-weight: 400;
    font-size: 1em;
    padding: 20px 0 20px 40px;
    margin: 25px 0 0 0;
    overflow: hidden;
    box-sizing: border-box;
    display:block;
    color:rgba(0,0,0,0.8);
    border-top:1px solid rgba(0,0,0,0.1);
    background: rgba(251, 197, 49, 0.05);
}


.rec-pagination{
    display:none !important;
}
.rec-slider-container{
    margin: 0 !important;
}
.carousel-title{
    font-size: 1.1em;
    font-weight: 400;
    vertical-align: top;
    max-width: 120px;
    margin: 1em 50px 0 0;
    display:inline-block;
    white-space: pre-wrap;
    line-height: 1.5;
    position: relative;
}
.carousel-title::after{
    content:'';
    position: absolute;
    bottom:-1em;
    right:-1em;
    height: 1em;
    width: 1em;
    display: block;
    background: url("../img/right-arrow.svg") center center no-repeat;
}
.carousel-title::before{
    content:'';
    position: absolute;
    bottom: -0.6em;
    right:-0.6em;
    height: 3px;
    width: 7.5em;
    display: block;
    background: #000;
}
.carousel .prev{
    left:0;
}
.carousel:after{
    position: absolute;
    content: '';
    width: 3.25em;
    height: 100%;
    right:0;
    top:0;
    background: transparent;
    background: linear-gradient(to right, transparent, #f8f8f8, #f8f8f8);
    display:block;
    z-index: 200;
}
.carousel .next{
    right: 0;
}
.carousel .hide{
    display:none !important;
}
@media only screen and (min-width: 40em) {
    .carousel .arrow {
        top:45%;
        z-index:1000;
        position: absolute;
        background: #dfdfdf !important;
        border-radius: 50%;
        color: rgba(0,0,0,0.7) !important;
        font-size: 1em;
        padding:0 !important;
        transform: translate(0,-50%);
        outline: none !important;
        border: 10px solid #f8f8f8;
        box-sizing: border-box;
        line-height: 1.5em;
        box-shadow: none;
        width:50px;
        height: 50px;
        cursor: pointer;
    }
    .carousel .arrow:hover {
        background:#a4a4a4 !important;
    }
}

.rec.rec-arrow:disabled {
    visibility: hidden;
}
.rec-carousel-item:focus {
    outline: none;
}
.rec.rec-arrow:target{
    color:#000
}

@media only screen and (min-width: 40em) {
    .carousel{
        overflow-x: hidden;
        overflow-y: visible;
        scroll-behavior: smooth;
        white-space: nowrap;
    }
}
@media only screen and (max-width: 39em) {
    .carousel{
        overflow-x: scroll;
        overflow-y: visible;
        scroll-behavior: smooth;
        white-space: nowrap;
    }
}

.content .delimiter{
    width:calc(100% - 2em);
    height: 1px;
    background: transparent;
    border-bottom: 1px solid rgba(0,0,0,0.2);
    display:block;
    margin: -1em 1em 0.5em 1em;
}


@media only screen and (min-width: 40em) {
    .section .select{
        width: calc(100% - 0em);
    }
}
@media only screen and (max-width: 39em) {
    .section .select{
        width: 100%;
    }
}
.section .select{
    margin: 0 0 1em 0;
    overflow: visible;
    border-radius: 8px;
}
.section .select input[type="text"],
.section .select input[type="text"]:focus{
    box-shadow: none !important;
    padding: 1em 0.5em !important;
}
.section .select__control{
    background-color: rgba(255,255,255,1) !important;
    border: 1px solid rgba(0,0,0,0.3) !important;
    border-radius: 8px !important;
    box-shadow: none;
}
.section .select__menu{
    border-radius: 8px !important;
    box-shadow: 0 0 5px rgba(0,0,0,0.2) !important;
    border: 1px solid #fbc531 !important;
    overflow: hidden;
    padding: 0.25em 0;
}
.section .select__singleValue,
.section .select__single-value,
.section .select__placeholder{
    margin-left:0.5em !important;
}
.section .select__control:hover,
.section .select__control:focus{
    border: 1px solid #fbc531 !important;
    box-shadow: 0 0 2px #fbc531 !important;
}

.section .loading{
    background-color: #cecece !important;
    border:1px solid transparent !important;
    background-size: 1.5em !important;
    background-repeat: no-repeat !important;
    background-position: right 0.5em center !important;
    background-image: url("../img/loader-black.svg") !important;
    padding: 0.5em 3em 0.5em 1.5em !important;
}
.section input[type="submit"],
.section-half input[type="submit"] {
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: 17px 16px;
    padding: 0.5em 1.5em;
    margin: 1em 0 0 0;
    color: #202020;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: auto;
    box-sizing: border-box;
    outline-style: none;
    box-shadow: none;
    border:1px solid rgba(0,0,0,0.3);
    display:inline-block;
    border-radius: 1.5em;
    background: rgba(0,0,0,0.0);
    cursor: pointer;
}
.section input[type="submit"]:hover,
.section-half input[type="submit"]:hover{
    background: #fbc531;
    border:1px solid transparent;
}
.section input[type="text"]:disabled,
.section input[type="password"]:disabled,
.section-half input[type="text"]:disabled,
.section-half input[type="password"]:disabled{
    border:1px solid rgba(0,0,0,0.1);
    background: rgba(0,0,0,0.05);
}
@media only screen and (min-width: 40em) {
    .section input[type="text"],
    .section input[type="password"],
    .section-half input[type="text"],
    .section-half input[type="password"]{
        width: calc(100% - 0em);
    }
}
@media only screen and (max-width: 39em) {
    .section input[type="text"],
    .section input[type="password"],
    .section-half input[type="text"],
    .section-half input[type="password"]{
        width: 100%;
    }
}

.section input[type="text"],
.section input[type="password"],
.section-half input[type="text"],
.section-half input[type="password"]{
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: 17px 16px;
    border-radius: 8px;
    padding: 5px 15px;
    margin: 0 0 0.75em 0;
    color: #202020;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    height: 52px;
    box-sizing: border-box;
    outline-style: none;
    box-shadow: none;
    border:1px solid rgba(0,0,0,0.3);
    display:inline-block;
    background: rgba(0,0,0,0.0);
}

.section input[type="text"]:focus,
.section input[type="password"]:focus,
.section-half input[type="text"]:focus,
.section-half input[type="password"]:focus{
    border:1px solid #fbc531;
    background: #fff;
    color:rgba(0,0,0,0.9);
    box-shadow: 0 0 3px rgba(251, 197, 49, 0.7);
}
@media only screen and (min-width: 40em) {
    .container .split2{
        width: calc((100% / 2) - 0.25em - 0em) !important;
        margin: 0 0 0.75em 0 !important;
    }
    .container .split3{
        width: calc((100% / 3) - 0.25em) !important;
        margin: 0 0 0.75em 0 !important;
    }
    .container .split13{
        width: calc(2*(100% / 3) - 0.25em - 0em) !important;
        margin: 0 0 0.75em 0 !important;
    }
    .container .split4{
        width: calc((100% / 3.5) - 0.25em) !important;
        margin: 0 0 0.75em 0 !important;
    }
    .container .split14{
        width: calc(2.5*(100% / 3.5) - 0.25em - 0em) !important;
        margin: 0 0 0.75em 0 !important;
    }
    .container .split-space{
        margin: 0 0.5em 0.75em 0 !important;
    }
    .container .toggle-btn{
        width:calc(100% - 0em - 2px);
    }
}
@media only screen and (max-width: 39em) {
    .container .split2{
        width: calc((100% / 2) - 0.25em) !important;
        margin: 0 0 0.75em 0 !important;
    }
    .container .split3{
        width: calc((100% / 3) - 0.25em) !important;
        margin: 0 0 0.75em 0 !important;
    }
    .container .split13{
        width: calc(2*(100% / 3) - 0.25em) !important;
        margin: 0 0 0.75em 0 !important;
    }
    .container .split4{
        width: calc((100% / 3.5) - 0.25em) !important;
        margin: 0 0 0.75em 0 !important;
    }
    .container .split14{
        width: calc(2.5*(100% / 3.5) - 0.25em) !important;
        margin: 0 0 0.75em 0 !important;
    }
    .container .split-space{
        margin: 0 0.5em 0.75em 0 !important;
    }
    .container .toggle-btn{
        width:calc(100% - 2px);
    }
}
.container .toggle-btn{
    border: 2px solid rgba(0,0,0,0.2);
    border-radius:10px;
    height:50px;
    display:block;
    box-sizing:border-box;
    padding: 5px 0 5px 5px;
    margin: 0 0 1.5em 0;
    position: relative;
}
.container .toggle-btn .btn {
    display: inline-block;
    padding: 10px 15px;
    position: relative;
    text-align: center;
    box-sizing:border-box;
    width:calc(50% - 2.5px);
    transition: background 600ms ease, color 600ms ease;
}

.container .toggle-btn input[type=radio].toggle {
    display: none;
}
.container .toggle-btn input[type=radio].toggle + label {
    cursor: pointer;
}
.container .toggle-btn input[type=radio].toggle + label:hover {
    background: none;
    color: #1a1a1a;
}
.container .toggle-btn input[type=radio].toggle + label:after {
    background: rgba(251,197,49,0.5);
    content: "";
    position: absolute;
    top: 0;
    left:0;
    text-align:center;
    transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
    z-index: -1;
    box-sizing:border-box;
    display:block;
    width:calc(100% + 5px);
    height: 37px;
    border-radius:7px;
}
.container .toggle-btn input[type=radio].toggle.toggle-left + label {
    border-right: 0;
}
.container .toggle-btn input[type=radio].toggle.toggle-left + label:after {
    left: 100%;
}
.container .toggle-btn input[type=radio].toggle.toggle-right + label {
    margin-left: -5px;
}
.container .toggle-btn input[type=radio].toggle.toggle-right + label:after {
    left: -100%;
}
.container .toggle-btn input[type=radio].toggle:checked + label {
    cursor: default;
    color: #000;
    transition: color 200ms;
    z-index: 100;
}

.container .toggle-btn input[type=radio].toggle:checked + label:after {
    left: 0;
}
@media only screen and (min-width: 40em) {
    .container .order-summary{
        position: sticky;
        bottom:0;
        left:0;
        width: 100%;
        height: auto;
        display:block;
        box-sizing: border-box;
        border-top:1px solid rgba(0,0,0,0.1);
        padding: 1.2em 3em;
        box-shadow: 0px -6px 6px rgba(0,0,0,0.03);
        z-index: 1000;
        background: #fff;
    }
    .container .order-summary ul{
        display:flex;
        width: 100%;
        height: auto;
        justify-content: left;
        flex-flow: row;
    }
    .container .order-summary .panel li{
        position: relative;
        height: auto;
        width: auto;
        min-width: 6em;
        background: transparent;
        border-right: 1px solid rgba(0,0,0,0.2);
        padding: 0.2em 0 0.2em 2em;
        text-align: left;
    }
    .container .order-summary .panel li:first-child{
        padding: 0.2em 0;
    }
    .container .order-summary .panel li:last-child{
        border:none;
    }
    .container .order-summary .panel .dollar-icon{
        padding: 0.2em 0 0.2em 5em !important;
    }
    .container .order-summary .panel .dollar-icon:after{
        content:'';
        position: absolute;
        top:50%;
        transform: translate(0,-50%);
        left:0;
        width:1.75em;
        height: 1.75em;
        display: block;
        background: url("../img/dollar.svg") center center no-repeat;
        background-size: contain;
    }
    .container .order-summary .panel li .title{
        display: block;
        font-size: 1.1em;
        font-weight: 500;
        color:rgba(0,0,0,0.6);
    }
    .container .order-summary .panel li .title i{
        opacity:0.8;
    }
    .container .order-summary .panel li .value{
        display: block;
        margin: 0.5em 0 0 0;
        font-size: 1.5em;
    }
}

@media only screen and (max-width: 39em) {
    .container .order-summary{
        position: sticky;
        bottom:0;
        left:0;
        width: 100%;
        height: auto;
        display:block;
        box-sizing: border-box;
        border-top:1px solid rgba(0,0,0,0.1);
        padding: 1em 1.35em;
        min-height: 4.5em;
        box-shadow: 0px -6px 6px rgba(0,0,0,0.03);
        z-index: 1000;
        background: #fff;
        font-size: 0.9em;
    }
    .container .order-summary ul{
        display:block;
        width: 100%;
        height: auto;
    }
    .container .order-summary .panel li{
        position: relative;
        height: auto;
        width: 100%;
        padding: 0.2em 0 0.2em 0;
        text-align: left;
        display: block;
        box-sizing: border-box;
    }
    .container .order-summary .panel li .title{
        display: inline-block;
        font-weight: 500;
        color:rgba(0,0,0,0.6);
    }
    .container .order-summary .panel li .value{
        display: inline-block;
        margin: 0 0 0 0.5em;
        position: absolute;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
}

.container .description{
    text-align: left;
    font-size: 0.85em;
    display:block;
    line-height: 1.5;
    padding: 0.5em 0 0.5em 3em;
    background: url("../img/order-information.svg") left center no-repeat;
    background-size: 25px;
    color:rgba(0,0,0,0.7);
}
.center_info img{
    position:absolute;
    top:0;
    left:50%;
    transform: translate(-50%,0);
    width:50px;
    height: 50px;
    display:inline-block;
}
.center_info{
    position:sticky;
    top:35vh;
    box-sizing: border-box;
    width:100%;
    z-index:10000;
    display:block;
    text-align: center;
    padding:75px 0 0 0;
    color:#000;
    font-weight: 400;
}
.center_info h1{
    color:#000;
    font-weight: 400;
    font-size: 1.2em;
    margin:0;
}
.full_error{
    position:fixed;
    bottom:0;
    left:0;
    box-sizing: border-box;
    width:100%;
    padding:1em 5em;
    background: #c0392b;
    color:#fff;
    z-index:10000;
    transform: translate(0,5em);
    animation: slideInFromBottom 5s;
}
.full_info{
    position:fixed;
    bottom:0;
    left:0;
    box-sizing: border-box;
    width:100%;
    padding:1em 5em;
    background: #27ae60;
    color:#fff;
    z-index:10000;
    transform: translate(0,5em);
    animation: slideInFromBottom 5s;
}
@keyframes slideInFromBottom {
    0%{
        transform: translate(0,5em);
    }
    10% {
        transform: translate(0,0);
    }
    90% {
        transform: translate(0,0);
    }
    100%{
        transform: translate(0,5em);
    }
}
.container .order-summary .panel .order-info,
.container .order-summary .panel .order-error,
.container .order-summary .panel .order{
    padding: 0.75em 2em;
    border-radius: 25px;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    display: block;
    transition: background 0.65s, color 0.65s;
    width: auto;
    box-sizing: border-box;
    position: absolute;
    top:50%;
    right: 3em;
    transform: translate(0,-50%);
    text-align: center;
}
.container .order-summary .panel .order-info{
    border: 1px solid transparent;
    color: #202020;
    background:rgba(0,0,0,0.1);
}
.container .order-summary .panel .order-error{
    border: 1px solid transparent;
    color: #902b20;
    background:rgba(231, 76, 60,0.15);
}
.container .order-summary .panel .order{
    background-color: transparent;
    border: 1px solid #202020;
    color: #202020;
}
.container .order-summary .panel .order:hover {
    background-color: #fbc531;
    border:1px solid transparent;
    transition: all 0.65s;
    color: #000;
}
@media only screen and (max-width: 39em) {
    .container .order-summary .panel .order-info,
    .container .order-summary .panel .order-error,
    .container .order-summary .panel .order{
        padding: 0.75em 1.75em !important;
        font-size: 15px !important;
        right: 1.35em !important;
    }
    .container .order-summary .panel .order{
        padding: 0.6em 1.75em !important;
    }
}


@media only screen and (min-width: 40em) {
    .content .hbox{
        width:calc((100%/3) - 1em);
    }
}
@media only screen and (max-width: 39em) {
    .content .hbox{
        width:calc((100%/2) - 1em);
    }
}
.content .hbox{
    min-height: 10em;
    display:inline-block;
    background: rgba(255,255,255,1);
    border:1px solid rgba(0,0,0,0.2);
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    box-sizing: border-box;
    border-radius: 15px;
    margin: 0 15px 15px 0;
    overflow: visible;
    position: relative;
    text-align: center;
    z-index: 100;
    transform: scale(1);
    transition: transform 0.3s ease-out;
    cursor: pointer;
    color:#202020;
}
.content .hbox-active{
    background: #fffbf1;
    border:1px solid #fbc531;
}
.content .hbox-active:after{
    content:'';
    width: 100%;
    height: 100%;
    border:2px solid #fbc531;
    position: absolute;
    top:-2px;
    left:-2px;
    display:block;
    border-radius: 15px;
}
.content .hbox .title{
    display:block;
    margin: 0.9em 0 0em 0;
    font-size: 1.8em;
    font-weight: 600;
    color:rgba(0,0,0,0.8);
    position: relative;
}
.content .hbox .params{
    position: relative;
    display:inline-block;
    font-size: 0.7em;
    font-weight: 400;
    margin: 0em 0 0em 0;
    width: 100%;
    padding: 0.8em 3em 1em 3em;
    white-space: break-spaces;
    line-height: 1.5;
    overflow: visible;
    box-sizing: border-box;
}
.content .hbox .params i{
     display:block;
 }
.content .hbox .price{
    display:block;
    margin: 0 0 0 0;
    font-size: 0.9em;
    font-weight: 400;
    z-index:200;
    position: relative;
    color:rgba(0,0,0,0.8);
    border-top:1px solid rgba(0,0,0,0.1);
    padding:0.75em 0 0.9em 0;
    background: rgba(0,0,0,0);
}

@media only screen and (min-width: 40em) {
    .content .img-box{
        width:calc((100%/3) - 1em);
    }
}
@media only screen and (max-width: 39em) {
    .content .img-box{
        width:calc((100%/2) - 1em);
    }
}
.content .img-box{
    height: 8em;
    display:inline-block;
    background: rgba(255,255,255,1);
    border:1px solid rgba(0,0,0,0.15);
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    box-sizing: border-box;
    border-radius: 15px;
    margin: 0 1em 1.2em 0;
    overflow: visible;
    position: relative;
    text-align: left;
    z-index: 100;
    transform: scale(1);
    transition: transform 0.3s ease-out;
    cursor: pointer;
}

.content .img-box .title{
    display:block;
    bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
    z-index:200;
    width: 100%;
    position: absolute;
    color:rgba(0,0,0,0.8);
    border-top:1px solid rgba(0,0,0,0.1);
    padding:0.75em 0 0.9em 0;
    background: rgba(0,0,0,0);
    text-align: center;
}
.content .img-box img{
    position: absolute;
    left:50%;
    transform: translate(-50%,0);
    top:1em;
    height: 3.5em;
}
.content .img-box-active{
    background: #fffbf1;
    border:1px solid #fbc531;
}
.content .img-box-active:after{
    content:'';
    width: 100%;
    height: 100%;
    border:2px solid #fbc531;
    position: absolute;
    top:-2px;
    left:-2px;
    display:block;
    border-radius: 15px;
}

.full-warning{
    width:100%;
    margin: 1.5em 1.5em 0 0;
    text-align: left;
    padding:1em 1em 1em 3em;
    background-color: rgba(253, 224, 145, 0.35);
    background-image: url("../img/warning_icon_black.svg");
    background-size: 20px;
    background-position: 1em center;
    background-repeat: no-repeat;
    color:#000;
    box-sizing: border-box;
    border-radius: 8px;
}

.content .credit-banner{
    width:calc(100% - 1.7em);
    position: relative;
    background: #fff;
    height: auto;
    display:block;
    box-sizing: border-box;
    margin: 1em 0.7em 1em 1em;
    border-radius: 12px;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    border:1px solid rgba(0,0,0,0.05);
    color:#202020;
    padding: 1.1em 2em;
    overflow: hidden;
}

.content .credit-banner .title{
    position: relative;
    font-weight: 600;
    font-size: 0.7em;
    text-transform: uppercase;
    margin: 0 0 0.5em 0;
    color:rgba(0,0,0,0.4);
}
.content .credit-banner .value{
    position: relative;
    font-weight: 400;
    font-size: 1.4em;
}
.content .credit-banner .value i{
    color:rgba(0,0,0,0.8);
}
.content .credit-banner .add{
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
    right:2em;
    text-decoration: none;
    display:inline-block;
    background:transparent;
    border-radius: 2em;
    border:1px solid rgba(0,0,0,0.5);
    color:#1c1a1b;
    font-size: 1em;
    font-weight: 400;
    padding: 0.5em 1em;
}
.content .credit-banner .add:hover,
.content .credit-banner .add:focus{
    background:#fbc531;
    border:1px solid #fbc531;
}
.content .banner{
    width:calc(100% - 1.4em);
    position: relative;
    background: #fff;
    min-height: 7em;
    display:block;
    box-sizing: border-box;
    margin: 1em 0.7em 1em 0.7em;
    border-radius: 12px;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    border:1px solid rgba(0,0,0,0.05);
    color:#202020;
    padding: 1.7em 2em;
    overflow: hidden;
}
@media only screen and (min-width: 40em) {
    .content .banner::before{
        position: absolute;
        content: '';
        top:-15vw;
        right:-18vw;
        width:50vw;
        height: 50vw;
        background: #fcd465;
        border-radius: 50%;
        box-shadow:0 8px 15px rgba(0,0,0,0.25);
    }

    .content .banner::after{
        position: absolute;
        content: '';
        top:-10vw;
        right:-13vw;
        width:40vw;
        height: 40vw;
        background: #fbc531;
        border-radius: 50%;
        box-shadow:0 8px 25px #fbc531;
    }
}

@media only screen and (max-width: 39em) {
    .content .banner::before{
        position: absolute;
        content: '';
        bottom:-30.5vw;
        right:-10vw;
        width:45vw;
        height: 45vw;
        background: #fcd465;
        border-radius: 50%;
        box-shadow:0 8px 15px rgba(0,0,0,0.25);
    }

    .content .banner::after{
        position: absolute;
        content: '';
        bottom:-25.5vw;
        right:-5vw;
        width:35vw;
        height: 35vw;
        background: #fbc531;
        border-radius: 50%;
        box-shadow:0 8px 25px #fbc531;
    }
}

.content .banner h2{
    font-size: 1.45em;
    font-weight: 600;
    margin: 0 0 0.5em 0;
}
.content .banner h3{
    font-size: 1.25em;
    font-weight: 300;
    margin: 0 0 0.75em 0;
    line-height: 1.3;
}
@media only screen and (min-width: 40em) {
    .content .banner img{
        position: absolute;
        bottom:0;
        right: 5%;
        object-fit: contain;
        height: 80%;
        z-index:100;
        filter:drop-shadow(0 0 5px rgba(0,0,0,0.1));
    }
}
@media only screen and (max-width: 39em) {
    .content .banner img{
        position: absolute;
        bottom:0;
        right: 5%;
        object-fit: contain;
        height: 80%;
        z-index:100;
        filter:drop-shadow(0 0 5px rgba(0,0,0,0.1));
        display: none;
    }
}

.content .button-black {
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: 17px 16px;
    padding: 0.5em 1.5em;
    margin: 1em 0 0 0;
    color: #202020;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: auto;
    box-sizing: border-box;
    outline-style: none;
    box-shadow: none;
    border:1px solid rgba(0,0,0, 0.3);
    display:inline-block;
    border-radius: 1.5em;
    background: rgba(0,0,0,0.0);
    cursor: pointer;
    text-decoration: none;
}
.content .button-black:hover{
    background: #fbc531;
    border:1px solid transparent;
}
.content .button-arrow-right{
    background-image: url("../img/right-arrow.svg");
    background-size: 12px;
    background-repeat: no-repeat;
    background-position: right 1em center;
    padding: 0.5em 2.25em 0.5em 1.5em;
}
.content .button-white {
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: 17px 16px;
    padding: 0.5em 1.5em;
    margin: 1em 0 0 0;
    color: #202020;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: auto;
    box-sizing: border-box;
    outline-style: none;
    box-shadow: none;
    border:1px solid rgba(255,255,255, 1);
    display:inline-block;
    border-radius: 1.5em;
    background: rgba(255,255,255, 1);
    cursor: pointer;
    text-decoration: none;
}

.content .button-white:hover{
    background: rgba(0,0,0,0.7);
    border:1px solid rgba(255,255,255, 0);
    color:#fff;
}
.content .gift-card{
    margin: 0 !important;
}

.error-block{
    display:block;
    background: #fbe8e9 url("../img/warning_icon_red.svg") left 1.5em top 1.2em no-repeat;
    background-size: 1.7em 1.7em;
    border: 1px solid rgba(192, 57, 43, 0.15);
    color:rgba(192, 57, 43,1.0);
    box-sizing: border-box;
    padding: 1em 1.5em 1em 4.5em;
    border-radius: 0.5em;
    line-height: 1.4;
}

strong{
    font-weight: 600;
}