:root{
    --shadow-color: rgb(0 0 0 / .1);
    --box-shadow: 0 1px 3px 0 var(--shadow-color), 0 1px 2px -1px var(--shadow-color);
    --border-radius: 0.5em;
    --border-around: 1px solid rgba(0,0,0,0.1);
    --border-around-light: 1px solid rgba(0,0,0,0.05);
}
.card{
    box-shadow: var(--box-shadow);
}
.card-border{
    border:1px solid rgba(0,0,0,0.1);
}
.card-padding{
    padding:1em 1.5em;
}
.bg-white{
    background:#fff;
}
.bg-white-darker{
    background: #fbfbfb;
}

.details-container{
    box-shadow: 0 0 5px rgba(0,0,0,0.06);
}
details{
    position: relative;
    display: block;
    border-radius: var(--border-radius);
    border: 1px solid rgba(0,0,0,0.06);
    line-height: 1.4;
}
summary {
    padding: 1em 1.5em;
    background:#fff;
    cursor: pointer;
    position: relative;
    list-style: none;
    border-radius: inherit;
}
summary h1{
    font-size: 1.05em !important;
    font-weight: 400 !important;
}
summary h2{
    font-size: 0.9em !important;
    font-weight: 400 !important;
    color:rgba(0,0,0,0.6) !important;
}

.linked-details{
    border-radius: 0;
    border-bottom: 1px solid rgba(0,0,0,0.025) !important;
}
.linked-details:first-of-type{
    border-radius: 0.5em 0.5em 0 0;
}

.linked-details:last-of-type{
    border-radius: 0 0 0.5em 0.5em;
    border-bottom: 1px solid rgba(0,0,0,0);
}

details .body{
    border-top: 1px solid rgba(0,0,0,.15);
    border-left:2px solid #fbc531;
    padding:1em 1.5em;
}
details .body p{
    line-height: 1.3;
    margin: 0 0 0.75em 0;
    color:rgba(0,0,0,0.7);
}
summary::marker {
    display:none;
}
details .left-padding{
    padding-left:4.2em;
}
summary .icon{
    position: absolute;
    top:1em;
    left:1.5em;
    height:1.5em;
    width:1.5em;
    display:block;
}
summary .icon img{
    position: absolute;
    top:0;
    left:0;
    object-fit: contain;
    width: 100%;
    height: 100%;
}
summary .id {
    background: rgba(0,0,0,.1);
    border: 1px solid rgba(0,0,0,.05);
    border-radius: .52em;
    display: inline-block;
    font-size: .95em;
    font-weight: 600;
    padding: .05em .6em;
    position: relative;
    margin: 0 1em 0 0;
}
summary::before {
    content:'';
    position: absolute;
    right:1.5em;
    top:1.25em;
    width:1em;
    height: 1em;
    display:block;
    background-image: url("../src/img/arrow-right.svg");
    background-position: 0 0;
    background-repeat: no-repeat;
    background-size: contain;
    transform: rotate(0deg);
    transition: transform 0.4s;
}

details[open] > summary::before {
    transform: rotate(90deg);
}
details summary::-webkit-details-marker {
    display:none;
}

label{
    position: relative;
    font-size: 0.9em;
    color:rgba(0,0,0,0.7);
}




.section .options,
.section-half .options{
    width:100%;
}

.section .option-small,
.section-half .option-small{

}
@media only screen and (min-width: 40em) {
    .section .option,
    .section-half .option{
        width:calc((100% - 2em) / 3);
        min-height: 10em;
        display:inline-block;
        background: rgba(255,255,255,1);
        border:1px solid rgba(0,0,0,0.15);
        box-sizing: border-box;
        border-radius: 15px;
        margin: 0 1em 1em 0;
        overflow: visible;
        position: relative;
        text-align: center;
        transform: scale(1);
        box-shadow: 0 0 8px rgba(0,0,0,0.05);
        cursor: pointer;
        padding: 0 0 3em 0;
    }
    .option:nth-child(3n){
        margin: 0 0 1em 0 !important;
    }
    .section .option-empty,
    .section-half .option-empty{
        width:calc((100%/3) - 1em);
        min-height: 10em;
        display:inline-block;
        border:1px solid rgba(0,0,0,0.2);
        box-sizing: border-box;
        border-radius: 15px;
        margin: 0 15px 15px 0;
        overflow: visible;
        position: relative;
        text-align: center;
        transform: scale(1);
    }
}

@media only screen and (max-width: 39em) {
    .section .option,
    .section-half .option{
        width:calc((100%/2) - 1em);
        min-height: 10em;
        display:inline-block;
        background: rgba(255,255,255,1);
        border:1px solid rgba(0,0,0,0.15);
        box-sizing: border-box;
        border-radius: 15px;
        margin: 0 15px 15px 0;
        overflow: visible;
        position: relative;
        text-align: center;
        transform: scale(1);
        box-shadow: 0 0 8px rgba(0,0,0,0.05);
        cursor: pointer;
        padding: 0 0 3em 0;
    }
    .section .option-empty,
    .section-half .option-empty{
        width:calc((100%/2) - 1em);
        min-height: 10em;
        display:inline-block;
        border:1px solid rgba(0,0,0,0.2);
        box-sizing: border-box;
        border-radius: 15px;
        margin: 0 15px 15px 0;
        overflow: visible;
        position: relative;
        text-align: center;
        transform: scale(1);
    }
}

.section .option-active{
    background: #fffbf1;
    border:1px solid #fbc531;
}
.section .option-active:after{
    content:'';
    width: 100%;
    height: 100%;
    border:2px solid #fbc531;
    position: absolute;
    top:-2px;
    left:-2px;
    display:block;
    border-radius: 15px;
}

.section .option .title,
.section-half .option .title{
    display:block;
    bottom: 0;
    font-size: 0.9em;
    font-weight: 400;
    width: 100%;
    position: absolute;
    color:rgba(0,0,0,0.8);
    border-top:1px solid rgba(0,0,0,0.1);
    padding: 1.1em 0 1.1em 0;
    background: rgba(0,0,0,0);
    text-align: center;
}
.section .option .subtitle,
.section-half .option .subtitle{
    display:block;
    bottom: 0;
    font-size: 0.85em;
    font-weight: 400;
    width: 100%;
    position: relative;
    color:rgba(0,0,0,0.8);
    border-top:1px solid rgba(0,0,0,0.1);
    padding: 0.70em 0 0.5em 0;
    text-align: center;
    margin: 0.75em 0 0 0;
}
.section .option img,
.section-half .option img{
    position: absolute;
    left:50%;
    transform: translate(-50%,-50%);
    top:calc(50% - 1.2em);
    height: 2.5em;
}

.option h1{
    display:block !important;
    margin: 0.9em 0 0.35em 0 !important;
    font-size: 1.8em !important;
    font-weight: 700 !important;
    color:rgba(0,0,0,0.8) !important;
    position: relative !important;
}
.option h2,
.option h3,
.option h4,
.option h5{
    display:block;
    margin: 0.4em 0 0.25em 0;
    font-weight: 400;
    color:rgba(0,0,0,0.8);
    position: relative;
}
.option h2{
    font-size: 1.4em;
}
.option h3{
    font-size: 1em;
}
.option h4{
    font-size: 0.8em;
}
.option h5{
    font-size: 0.7em;
}



.none-icon:before{
    background-image: url("./img/chip.svg") !important;
}
.php-icon:before{
    background-image: url("./img/langs/php.svg") !important;
}
.python-icon:before{
    background-image: url("./img/langs/python.svg") !important;
}
.nodejs-icon:before{
    background-image: url("./img/langs/nodejs.svg") !important;
}
.golang-icon:before{
    background-image: url("./img/langs/golang.svg") !important;
}
.ruby-icon:before{
    background-image: url("./img/langs/ruby.svg") !important;
}
.swoole-php-icon:before{
    background-image: url("./img/langs/swoole-php.svg") !important;
}
.deno-icon:before{
    background-image: url("./img/langs/deno.svg") !important;
}

.changelog-scrollable{
    overflow-y: scroll;
    max-height: 10em;
    padding:0.5em 1em 0.5em 1.5em;
    font-size: 0.9em;
}