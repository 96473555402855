.form-container{
    position:absolute;
    top:0;
    left:0;
    width:100vw;
    display:block;
    min-height: 100vh;
}
.form-page{
    position: absolute;
    top: 18vh;
    left:50%;
    transform: translate(-50%,0);
    max-width:400px;
    width:100%;
    height: 400px;
    line-height: 1.5;
    display: block;
    text-align: center;
}
.form-page:after{
    content:'';
    position: absolute;
    top:-3.5em;
    left:50%;
    transform: translate(-50%,0);
    width: 100%;
    height:2.5em;
    background: url("../../img/futrou.svg") center top no-repeat;
    background-size: auto 2em;
}
.form-box{
    position: relative;
    display:block;
    width:100%;
    box-sizing: border-box;
    border-radius: 15px;
    z-index: 100;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.03), 0 3px 12px rgba(0, 0, 0, 0.06);
    border-top:2px solid #fbc531;
    overflow: hidden;
    background: #fff;
    background-size: 40px;
    padding: 90px 35px 35px 35px;
    text-align: left;
    transition: all 0.5s !important;
    animation: zoomInI 0.5s;
}
.form-box .loader{
    position: relative;
    width: 50px;
    height: 50px;
    background: url("../../img/loader-black.svg") center center no-repeat;
    background-size: 100%;
    display:block;
    margin: 155px 0;
}
.form-box h2 {
    font-size: 29px;
    font-weight: 300;
    line-height: 1.3;
    text-align: left;
    margin-bottom: 7px;
    color: rgba(0,0,0,0.7);
}
.form-box h3 {
    font-size: 17px;
    font-weight: 400;
    line-height: 1.3;
    text-align: left;
    margin-bottom: 15px;
    color: rgba(0,0,0,0.7);
}
.form-page .step{
    position: absolute;
    top:1.8em;
    left:1.9em;
    font-size: 1.1em;
    font-weight: 400;
    color:rgba(0,0,0,0.8);
    background-color: rgba(249, 202, 73, 0.25);
    border-radius: 10px;
    padding: 0.5em 0.8em;
    box-sizing: border-box;
}
.form-page .top-error{
    position: absolute;
    top:1.8em;
    left:1.9em;
    color:rgba(0,0,0,0.8);
    background-image: url("../../img/warning_icon_red.svg");
    background-size: auto 75%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    box-sizing: border-box;
    height: 3em;
    width: 3em;
}
.form-page .top-success{
    position: absolute;
    top:1.8em;
    left:1.9em;
    color:rgba(0,0,0,0.8);
    background-image: url("../../img/success_icon_green.svg");
    background-size: auto 75%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    box-sizing: border-box;
    height: 3em;
    width: 3em;
}
.form-page .bottom{
    text-align: center;
    padding: 1.25em 0 0 0;
}

.form-page .bottom a{
    text-decoration: none;
    margin: 0 0 0 0;
    color: rgba(0,0,0,0.4);
    display:inline-block;
    border:1px solid rgba(0,0,0,0);
    border-radius:1.5em;
    padding: 11px 2.5em 11px 1.5em;
    position: relative;
}
.form-page .bottom a:after{
    content:'';
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
    right:10px;
    height: 25px;
    width: 25px;
    background: url("../../img/right-arrow.svg") center center no-repeat;
    background-size: auto 15px;
    display:block;
    opacity:0.5;
}
.form-page .bottom a:nth-child(2){
    margin: 0 0 0 1em;
}

.form-page .bottom a:hover{
    border:1px solid rgba(0,0,0,0);
    background:rgba(0,0,0,0.1);
    color:rgba(0,0,0,0.8);
}

.form-page .email-icon{
    background-image: url("../../img/user.svg");
    padding: 5px 20px 5px 48px !important;
}
.form-page .password-icon{
    background-image: url("../../img/password.svg");
    padding: 5px 20px 5px 48px !important;
}
.form-page .prev-icon{
    background-image: url("../../img/left-arrow.svg");
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: left 1em center;
}
.form-page .next-icon{
    background-image: url("../../img/right-arrow.svg");
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: right 1em center;
}
.form-page .next-loader{
    background-image: url("../../img/loader-white.svg");
    background-size: auto 75%;
    background-color: #37383a !important;
    background-repeat: no-repeat;
    background-position: right 0.5em center;
    color:#fff !important;
}
.form-page .split2{
    width: calc((100% / 2) - 0.25em) !important;
    margin: 1em 0 0 0 !important;
}
.form-page .split3{
    width: calc((100% / 3) - 0.25em) !important;
    margin: 1em 0 0 0 !important;
}
.form-page .split13{
    width: calc(2*(100% / 3) - 0.25em) !important;
    margin: 1em 0 0 0 !important;
}
.form-page .split4{
    width: calc((100% / 3.5) - 0.25em) !important;
    margin: 1em 0 0 0 !important;
}
.form-page .split14{
    width: calc(2.5*(100% / 3.5) - 0.25em) !important;
    margin: 1em 0 0 0 !important;
}
.form-page .split-space{
    margin: 1em 0.5em 0 0 !important;
}
.form-page .delimiter{
    position: relative;
    display: block;
    margin: 1em 0 0 0;
}
.form-page .prev{
    width:calc(100%/3) !important;
    float:left;
    border-radius: 25px 0 0 25px !important;
    padding: 12px 0 12px 1.5em !important;
}
.form-page .next{
    width:calc(2*(100%/3)) !important;
    float:left;
    border-radius: 0 25px 25px 0 !important;
    border-left:1px solid transparent !important;
    padding: 12px 1.5em 12px 0.5em !important;
    transition: background-position 0s !important;
}

.form-page .reset{
    width:calc(55%) !important;
    float:left;
    border-radius: 25px 0 0 25px !important;
    padding: 12px 0 12px 1.5em !important;
}
.form-page .finish{
    width:calc(45%) !important;
    float:left;
    border-radius: 0 25px 25px 0 !important;
    border-left:1px solid transparent !important;
    padding: 12px 1.5em 12px 0.5em !important;
    transition: background-position 0s !important;
}
.form-page .code-input{
    width:100%;
    height: 60px;
    margin: 1em 0 0 0;
}
.form-page .code-input input[type="text"]{
    width:calc((100% - 2em) / 6);
    height: 100%;
    margin: 0 0.4em 0 0;
    font-size: 1.5em;
    padding:5px 10px 5px 15px;
}
.form-page .code-input input[type="text"]:last-child{
    margin: 0 0 0 0;
}
.form-page input[type="text"],
.form-page input[type="password"] {
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: 17px 16px;
    border-radius: 8px;
    padding: 5px 15px;
    border: 1px solid transparent;
    margin: 1em 0 0 0;
    color: #202020;
    font-size: 17px;
    font-weight: 400;
    width: 100%;
    height: 52px;
    box-sizing: border-box;
    outline-style: none;
    box-shadow: none;
    background-color: rgba(0,0,0,0.1);
}
.form-box .select{
    margin: 1em 0 0 0;
}
.form-box .select__control{
    background-color: rgba(0,0,0,0.1) !important;
    border: 1px solid transparent !important;
    border-radius: 8px !important;
    box-shadow: none !important;
}
.form-box .select__control input{
    padding: 0 0.5em !important;
}
.form-box .select__menu{
    border-radius: 8px !important;
    box-shadow: 0 0 5px rgba(0,0,0,0.2) !important;
    border: 1px solid #fbc531 !important;
}
.form-box .select__singleValue,
.form-box .select__single-value,
.form-box .select__placeholder{
    margin-left:0.5em !important;
}
.form-box .select__control:hover,
.form-box .select__control:focus{
    border: 1px solid #fbc531 !important;
}
.form-box .select__control:focus{
    box-shadow: 0 0 5px #fbc531 !important;
}
.form-page .startover {
    padding: 1em 0 0 0 !important;
    border: 1px solid transparent !important;
    margin: 0 0 -0.5em 0 !important;
    height: auto !important;
    font-size: 0.9em !important;
}
.form-page .startover:hover {
    border: 1px solid transparent !important;
    background: transparent !important;
    color:#fef2d1 !important;
}

.form-page .bottom_container{
    position: absolute;
    bottom: 0;
    width:100%;
    height: auto;
}
.form-page input[type="submit"] {
    padding: 12px 0 12px 0;
    border-radius: 25px;
    background-color: #fff;
    border: 1px solid #202020;
    color: #202020;
    font-size: 17px;
    font-weight: 400;
    display: block;
    cursor: pointer;
    transition: background 0.65s, color 0.65s;
    width: 100%;
    height: 52px;
    box-sizing: border-box;
    margin: 1.5em 0 0 0;
}
.form-page input[type="submit"]:hover {
    background-color: #37383a;
    transition: background 0.65s, color 0.65s;
    color: #fff;
}
.form-page input[type="submit"]:focus,
.form-page input[type="submit"].focus {
    outline: 0;
}
.form-page .email-verification{
    position: relative;
    padding: 0 0 150px 0;
    text-align: left;
}
.form-page p{
    font-weight: 400;
    font-size: 1em;
    margin: 1.7em 0 0 0;
    color:rgba(0,0,0,0.7);
    position: relative;
}
.form-page p a{
    text-decoration: none;
    color:#a47a04;
}
.form-page p a:hover{
    color:#000;
}
.form-page .with-icon{
    padding: 1.5em 0 0 4em;
    font-size: 0.8em;
    border-top:1px solid rgba(0,0,0,0.3);
    margin: 1.5em 0 0 0;
}
.form-page .shield{
    position: absolute;
    left:-0.5em;
    top:-0.25em;
    width:50px;
    height: 100px;
    background: url("../../img/shield.svg") center center no-repeat;
    background-size: 100%;
    display:block;
}

.form-page .toggle-btn{
    border: 2px solid rgba(0,0,0,0.2);
    border-radius:10px;
    width:100%;
    height:50px;
    display:block;
    box-sizing:border-box;
    padding: 5px 0 5px 10px;
}
.form-page .toggle-btn .btn {
    display: inline-block;
    padding: 7px 15px;
    position: relative;
    text-align: center;
    box-sizing:border-box;
    width:calc(50% - 2.5px);
    transition: background 600ms ease, color 600ms ease;
}

.form-page .toggle-btn input[type=radio].toggle {
    display: none;
}
.form-page .toggle-btn input[type=radio].toggle + label {
    cursor: pointer;
}
.form-page .toggle-btn input[type=radio].toggle + label:hover {
    background: none;
    color: #1a1a1a;
}
.form-page .toggle-btn input[type=radio].toggle + label:after {
    background: #fef2d1;
    content: "";
    position: absolute;
    top: 0;
    left:0;
    text-align:center;
    transition: left 200ms cubic-bezier(0.77, 0, 0.175, 1);
    z-index: -1;
    box-sizing:border-box;
    display:block;
    width:100%;
    height: 37px;
    border-radius:7px;
}
.form-page .toggle-btn input[type=radio].toggle.toggle-left + label {
    border-right: 0;
}
.form-page .toggle-btn input[type=radio].toggle.toggle-left + label:after {
    left: 100%;
}
.form-page .toggle-btn input[type=radio].toggle.toggle-right + label {
    margin-left: -5px;
}
.form-page .toggle-btn input[type=radio].toggle.toggle-right + label:after {
    left: -100%;
}
.form-page .toggle-btn input[type=radio].toggle:checked + label {
    z-index: 100;
    cursor: default;
    color: #000;
    transition: color 200ms;
}

.form-page .toggle-btn input[type=radio].toggle:checked + label:after {
    left: 0;
}

@-webkit-keyframes zoomInI {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50% {
        opacity: 1;
    }
}
@keyframes zoomInI {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.9, 0.9, 0.9);
        transform: scale3d(0.9, 0.9, 0.9);
    }

    50% {
        opacity: 1;
    }
}

.form-box .error {
    margin: 1em 0 0 0 !important;
    text-align: center;
    position: relative;
    padding: 15px 20px 15px 20px;
    border-radius: 10px;
    font-size: 16px;
    width: 100%;
    background:rgba(192, 57, 43, 0.15);
    border:1px solid rgba(192, 57, 43, 1);
    line-height: 1.2;
    color: #902b20;
    box-sizing: border-box;
    animation: flipInX 0.5s;
    transform: scaleY(1);
    transform-origin: bottom;
}
.form-box .error::after {
    content: "";
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translate(-50%);
    width: 0;
    height: 0;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-top: 10px solid rgba(192, 57, 43,1.0);
}

.form-box .input-error{
    border:1px solid #c0392b !important;
    background-color: rgba(192, 57, 43, 0.15) !important;
}

.form-box .input-disabled{
}
