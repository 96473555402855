.logging-out{
    position: absolute;
    top:50%;
    left:50%;
    width:150px;
    height: 150px;
    transform: translate(-50%,-50%);
    background: url("../../img/loader-black.svg") center center no-repeat;
    background-size: 50%;
    text-align: center;
    padding:150px 0 0 0;
    box-sizing: border-box;
    font-size: 1.1em;
}