.orders-count{
    position: absolute !important;
    top:0.5em !important;
    left:1.5em !important;
    display:inline-block !important;
    text-align: right;
    font-size: 0.9em;
    z-index: 200;
}
.orders-count .count{
    padding: 0.35em 0.5em 0.25em 0.5em !important;
}
.orders-list{
    margin: 0 0 1em 0;
    padding:0 0 0 0;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    border:1px solid rgba(0,0,0,0.1);
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    background: #fff;
    position: relative;
}
.orders-list:after{
    content: '';
    position: absolute;
    top:1.5em;
    display:block;
    left:7.55em;
    width: 2px;
    height: calc(100% - 3.5em);
    background: #dfdfdf;
    z-index:10;
}
.orders-none{
    margin: 2em 0;
    border-radius: 10px;
    overflow: hidden;
    display:block;
    width:100%;
    height:6em;
    border:2px dashed rgba(0,0,0,0.15);
    box-shadow: none !important;
    position: relative;
}
.orders-none span{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display:inline-block;
    padding:1em 2em;
    border-radius: 2em;
    color:rgba(0,0,0,0.5);
}
.orders-list .order{
    width:100%;
    margin: 0 0;
    background-color: rgba(255,255,255,1);
    display: block;
    min-height: 2em;
    border-left: 3px solid transparent;
    border-right: 5px solid transparent;
    border-bottom:1px solid rgba(0,0,0,0.1);
    position: relative;
    box-sizing: border-box;
    padding: 1.7em 1.5em 1.7em 1.5em;
    text-decoration: none !important;
    outline: none;
    color:#202020;
    cursor: pointer;
    overflow: visible;
    transition: all 0.15s;
}
.orders-list .opened{
    background-color: rgba(0,0,0,0.05);
    border-left: 3px solid #fbc531;
    box-shadow: inset 10px 0 10px -10px rgba(0,0,0,0.05) !important;
}
.orders-list .order-detail{
    width:100%;
    background-color: rgba(0,0,0,0.01);
    display: block;
    min-height: 2em;
    border-left: 3px solid transparent;
    border-right: 5px solid transparent;
    border-bottom:1px solid rgba(0,0,0,0.1);
    position: relative;
    box-sizing: border-box;
    padding: 1.7em 1.5em 1.7em 20%;
    text-decoration: none !important;
    outline: none;
    color:#202020;
    overflow: visible;
    transition: all 0.15s;
    box-shadow: inset 0 -10px 10px -10px rgba(0,0,0,0.05);
}
.orders-list .order-detail:last-child{
    box-shadow: none !important;
}
.orders-list .order:before{
    content: '';
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
    display:block;
    left:7em;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: #9f9f9f;
    transition: all 0.15s;
    z-index:100;
}
.orders-list .order:hover{
    background: rgba(0,0,0, 0.05);
}
.orders-list .order:hover:before{
    background: #202020;
}
.orders-head{
    width:100%;
    display:block;
    position: relative;
    text-align: left;
    height: 2.5em;
    padding:0.8em 0 0.5em 0;
    font-size: 0.9em;
    margin: 1.8em 0 0 0;
    border-radius: 10px 10px 0 0;
    background: #fff;
    border:1px solid rgba(0,0,0,0.1);
    border-bottom:0 solid transparent;
    box-sizing: border-box;
    z-index: 100;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
}
.orders-head .id{
    position: absolute;
    left:20%;
}
.orders-head .state{
    position: absolute;
    left:40%;
}
.orders-head .credit{
    position: absolute;
    right:2em;
}
.orders-list .id{
    position: absolute;
    top:50%;
    left:20%;
    transform: translate(0,-50%);
    font-size: 1.1em;
    font-weight: 600;
}
.orders-list .created{
    position: absolute;
    left:1.5em;
    top:50%;
    transform: translate(0,-50%);
    font-size: 0.9em;
    font-weight: 400;
}
.orders-list .state{
    position: absolute;
    left:40%;
    top:50%;
    transform: translate(0,-50%);
    font-size: 1em;
    font-weight: 400;
}

.orders-list .credit{
    position: absolute;
    right:1.5em;
    top:50%;
    transform: translate(0,-50%);
    font-size: 1em;
    font-weight: 400;
}

.orders-list .waiting:before{
    background: #dfdfdf;
}
.orders-list .paid:before{
    background: #5bc086;
}
.orders-list .canceled:before{
    background: #eb776b;
}

.orders-list .state span{
    padding:0.3em 1em;
    white-space: nowrap;
    border-radius: 1.5em;
    font-size: 0.9em;
}
.orders-list .state .waiting{
    background: rgba(0,0,0,0.03);
    border: 1px solid rgba(0,0,0,0.05);
    color: rgba(0,0,0,0.6);
}
.orders-list .state .paid{
    background: rgba(39, 174, 96, 0.05);
    border: 1px solid rgba(39, 174, 96, 0.2);
    color:#208e4e;
}
.orders-list .state .canceled{
    background: rgba(231, 76, 60, 0.1);
    border: 1px solid rgba(231, 76, 60, 0.1);
    color: rgba(192, 57, 43,1.0);
}

.orders-list .cancel{
    position: absolute;
    left:1.5em;
    top:1.3em;
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: 17px 16px;
    padding: 0.5em 1em;
    margin: 1em 0 0 0;
    color: #c0392b;
    font-size: 13px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: auto;
    box-sizing: border-box;
    outline-style: none;
    box-shadow: none;
    border:1px solid #c0392b;
    display:inline-block;
    border-radius: 1.5em;
    background-color: transparent;
    cursor: pointer;
    text-decoration: none;
}
.orders-list .cancel:hover{
    background: #202020;
    color:#fff;
    border:1px solid transparent;
}

.orders-list .bottom{
    position: relative;
    width: 100%;
    vertical-align: top;
    display:flex;
    flex-direction: row;
    align-items: flex-start;
}

.orders-list .bottom .pay-with-card{
    position: relative;
    width: 14em;
    height: 3.6em;
    display:inline-block;
    background-color: #fff;
    background-image: url("../img/revolut_r.svg");
    background-position: left 1.2em center;
    background-repeat: no-repeat;
    background-size: 25px;
    border:1px solid rgba(0,0,0,0.1);
    text-decoration: none;
    color:#202020;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    padding:0.5em 3em 0.5em 3.7em;
    cursor:pointer;
    font-size: 0.9em;
    transition: all 0.3s;
}
.orders-list .bottom .pay-with-card:hover{
    border:1px solid #fbc531;
    background-color: rgba(0,0,0,0.025);
}

.orders-list .bottom .pay-with-card:after{
    content:'';
    position: absolute;
    right:1.5em;
    top:50%;
    transform: translate(0,-50%);
    width:15px;
    height: 15px;
    display: block;
    background: url("../img/right-arrow.svg") center center no-repeat;
    transition: all 0.3s;
}
.orders-list .bottom .pay-with-card:hover:after{
    right:1em;
}
.orders-list .bottom .pinvoice,
.orders-list .bottom .invoice{
    position: relative;
    width: 10.2em;
    display:inline-block;
    background-color: #fff;
    background-image: url("../img/pdf.svg");
    background-position: left 1em center;
    background-repeat: no-repeat;
    background-size: 25px;
    border:1px solid rgba(0,0,0,0.1);
    text-decoration: none;
    color:#202020;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    border-radius: 10px;
    overflow: hidden;
    box-sizing: border-box;
    padding:0.5em 1em 0.5em 3.7em;
    margin: 0 1em 0 0;
}
.orders-list .bottom .pinvoice:hover,
.orders-list .bottom .invoice:hover{
    border:1px solid #fbc531;
    background-color: rgba(0,0,0,0.025);
}
.orders-list .bottom .pinvoice h3,
.orders-list .bottom .invoice h3{
    font-size: 0.7em;
    margin: 0 0 0.5em 0;
}
.orders-list .bottom .pinvoice h2,
.orders-list .bottom .invoice h2{
    margin: 0;
}
.orders-list .top{
    margin: 0 0 1.5em 0;
    position: relative;
}
.orders-list .top p{
    font-size: 1em;
    color:rgba(0,0,0,0.8);
}
.orders-list .top p:nth-child(2){
    margin: 0 0 1em 0;
}
.orders-list .top p span{
    position: absolute;
    left:12em;
    font-weight: 600;
}
.orders-list .top p .copy-button{
    position: absolute;
    height:1.4em;
    width:2.5em;
    display:none;
    left:10.5em;
    outline:none;
    border-radius: 1em;
    border:1px solid rgba(0,0,0,0.3);
    background: rgba(0,0,0,0) url("../img/copy.svg") center center no-repeat;
    background-size: 0.8em;
    cursor: pointer;
    margin: 0;
}
.orders-list .top p .copy-button:hover{
    background: #fbc531 url("../img/copy.svg") center center no-repeat;
    background-size: 0.8em;
    border:1px solid #fbc531;
}
.orders-list .top p:hover .copy-button{
    display:inline-block;
}
.orders-list .qr{
    position: absolute;
    right:1.5em;
    top:1.5em;
    width: 9.5em;
    height: 9.5em;
    display:block;
    border:2px solid #fbc531;
    padding:0.5em;
    border-radius: 12px;
    animation: zoomIn 0.7s;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
}
.orders-list .qr:before{
    content: 'QR Platba';
    position: absolute;
    left:-3.8em;
    top:50%;
    transform: translate(0,-50%) rotate(-90deg);
    font-size: 0.8em;
    text-transform: uppercase;
}
@-webkit-keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}
@keyframes zoomIn {
    from {
        opacity: 0;
        -webkit-transform: scale3d(0.3, 0.3, 0.3);
        transform: scale3d(0.3, 0.3, 0.3);
    }

    50% {
        opacity: 1;
    }
}
.orders-list .qr img{
    width: 100%;
}
.orders-list .hide{
    display:none;
}

.card-order{
    box-sizing: border-box;
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: 17px 16px;
    border-radius: 8px;
    padding: 0.9em 1.5em 0.9em 1.5em;
    margin: 0 0 0.75em 0;
    color: rgba(0,0,0,0.6);
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    outline-style: none;
    border:1px solid rgba(0,0,0,0.3);
    display:inline-block;
    background: rgba(0,0,0,0.0);
    width: 100%;
    z-index: 100;
    position: relative;
    box-shadow: 0 0 5px rgba(0,0,0, 0.1);
}
.card-order span{
    position: absolute;
    right:1.5em;
    font-weight: 600;
    color:#000;
}

.card-form-container{
    position: relative;
    display:block;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
}
.card-form{
    box-sizing: border-box;
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: 17px 16px;
    border-radius: 8px;
    padding: 1.8em 1.5em 0.5em 1.5em;
    margin: 0 0 0.75em 0;
    color: #202020;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    outline-style: none;
    border:1px solid rgba(0,0,0,0.3);
    display:inline-block;
    background: rgba(0,0,0,0.0);
    width: 100%;
    z-index: 100;
    position: absolute;
    box-shadow: 0 0 7px rgba(0,0,0, 0.1);
}
.card-form:focus{
    border:1px solid #fbc531;
    background: #fff;
    color:rgba(0,0,0,0.9);
    box-shadow: 0 0 3px rgba(251, 197, 49, 0.7);
}
.card-label {
    position: absolute;
    top:1.55em;
    left:0;
    height: 1px;
    width: 100%;
    display: block;
    background: rgba(0,0,0,0.2);
}
.card-label-num{
    position: absolute;
    top:0.5em;
    left:4.3em;
    color:rgba(0,0,0,0.5);
    z-index: 1;
    font-size: 0.85em;
}
.card-label-exp{
    position: absolute;
    top:0.5em;
    left:15.3em;
    color:rgba(0,0,0,0.5);
    z-index: 1;
    font-size: 0.85em;
}
.card-label-cvc{
    position: absolute;
    top:0.5em;
    left:20.1em;
    color:rgba(0,0,0,0.5);
    z-index: 1;
    font-size: 0.85em;
}
.card-container{
    position: fixed;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background: rgba(0,0,0,0.75);
    display: block;
    z-index: 10000;
    opacity: 1;
    animation: opacityAn 0.5s;
}
.card-box{
    position: fixed;
    width:100%;
    max-width: 400px;
    min-height: 350px;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    background: #fff url("../img/loader-circle-orange.svg") center center no-repeat;
    background-size: 25%;
    box-sizing: border-box;
    padding: 2.5em 2em 1.5em 2em;
    border-radius: 15px;
    border-top:4px solid #fbc531;
    box-shadow: 0 0 10px rgba(0,0,0, 0.1);
    animation: slideInUp 0.5s;
    transform-origin: center center;
    transition: all 0.5s;
    opacity:1;
}
.card-box::after{
    content:'';
    position: absolute;
    top:-7em;
    left:50%;
    transform: translate(-50%,0);
    width:5em;
    height: 5em;
    background: url("../img/revolut-white.svg") center center no-repeat;
    background-size: contain;
}
.hide{
    display:none;
}

.card-container .close,
.card-container .close {
    padding: 0.5em 1.5em;
    color: rgba(255,255,255,0.8);
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: auto;
    box-sizing: border-box;
    outline-style: none;
    border:1px solid rgba(255,255,255,0.5);
    display:inline-block;
    border-radius: 1.5em;
    background: transparent;
    cursor: pointer;
    position: absolute;
    bottom: -4em;
    left:50%;
    transform: translate(-50%,0);
}
.card-container .close:hover,
.card-container .close:hover{
    border:1px solid rgba(255,255,255,1);
}

.card-container input[type="submit"],
.card-container input[type="submit"] {
    background-size: 17px;
    background-repeat: no-repeat;
    background-position: 1.5em center;
    background-image: url("../img/credit-card.svg");
    padding: 1em 1.5em;
    margin: 2em 0 0.75em 0;
    color: #202020;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: 100% !important;
    box-sizing: border-box;
    outline-style: none;
    border:1px solid transparent;
    display:inline-block;
    border-radius: 1.5em;
    background-color: #fbc531;
    cursor: pointer;
}
.card-container input[type="submit"]:hover,
.card-container input[type="submit"]:hover{
    background: #202020;
    color:#fff;
    border:1px solid transparent;
}

.card-container .bottom-info{
    width: 100%;
    display:block;
    margin:2.8em 0 0 0;
    text-align: center;
    font-size: 0.8em;
    color:rgba(0,0,0,0.5);
    line-height: 1.5;
    max-width: 94%;
}
@keyframes opacityAn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}
@keyframes slideInUp {
    from {
        -webkit-transform: translate3d(0, 100%, 0) translate(-50%,-50%);
        transform: translate3d(0, 100%, 0) translate(-50%,-50%);
        visibility: visible;
        opacity: 0;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0) translate(-50%,-50%);
        transform: translate3d(0, 0, 0) translate(-50%,-50%);
        opacity: 1;
    }
}


iframe[data-testid="card-popup-iframe"] > .card-popup{
    display: block !important;
}

.card-popup{
    position:fixed;
    top:calc(50% + 5em);
    left:50%;
    transform: translate(-50%,-50%);
    background: #fff;
    width:100%;
    max-width: 400px;
    height: 350px;
    z-index:1000000000;
    display:none;
    text-align: center;
    box-sizing: border-box;
    font-size: 1.3em;
    padding:3em 0 0 0;
    line-height: 1.5;
}

.card-popup .close,
.card-popup .close {
    padding: 0.5em 1.5em;
    color: #202020;
    font-size: 15px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: auto;
    box-sizing: border-box;
    outline-style: none;
    border:1px solid rgba(0,0,0,0.7);
    display:inline-block;
    border-radius: 1.5em;
    background: transparent;
    cursor: pointer;
    position: absolute;
    bottom:3em;
    left:50%;
    transform: translate(-50%,0);
}
.card-popup .close:hover,
.card-popup .close:hover{
    background: #202020;
    color:#fff;
}

.card-before-loading{
    background: #fff;
    width: 100%;
    height: auto;
    display:block;
    transition: all 0.5s;
}

.card-container .error{
    width: 100%;
    display:block;
    margin:1.7em 0 -2em 0;
    text-align: left;
    font-size: 0.8em;
    color:#c0392b;
    line-height: 1.5;
    max-width: 94%;
}

.hide-vis{
    opacity:0;
}