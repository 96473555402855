@media only screen and (min-width: 40em) {
    .logo{
        position: fixed;
        top:0.65em;
        left:4%;
    }
}
@media only screen and (max-width: 39em) {
    .logo{
        position: fixed;
        top:0.65em;
        left:5%;
    }
}
.logo{
    font-family: 'Segoe UI', sans-serif;
    font-weight: 700;
    font-size: 1.4em;
    color:#000;
    z-index:4500;
    width:100px;
    height: 42px;
    text-align: center;
    padding:8.5px 0;
    box-sizing: border-box;
}
.logo:after{
    content: 'BETA';
    position: absolute;
    top:58%;
    right:-4.5em;
    font-weight: 400;
    font-size: 0.5em;
    font-family: "Roboto", Verdana, Geneva, Tahoma, sans-serif;
    color:rgba(0,0,0,0.7);
    border-radius: 1em;
    background: #fbc531;
    padding:0.25em 0.5em;
    transform: translate(0,-50%);
}
.logo:before{
    content: '';
    position: absolute;
    width: 0.25em;
    height: 0.25em;
    top:58%;
    right:-0.4em;
    border-radius: 1em;
    background: #fbc531;
    transform: translate(0,-50%);
}
.logo img{
    width:100%;
    object-fit:cover;
}