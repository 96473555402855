
.serverlet_content .settings-box{
    height:auto;
    animation: none !important;
    margin: 0.1em 0 0 0 !important;
    box-shadow: 0 0 5px rgba(0,0,0,0.06);
    border: 1px solid rgba(0,0,0,0.1) !important;
    font-size: 1.05em;
}

.serverlet_content .settings-box h2{
    display:block;
    padding:0.85em 1.5em;
    box-sizing: border-box;
    border-bottom:1px solid rgba(0,0,0, 0.12);
    font-size: 0.86em;
}
.serverlet_content .settings-box .inner-content{
    padding:0.7em 1.5em;
}


.serverlet_content .settings-box input[type="submit"] {
    float:right;
    margin: 0.5em 0 1.5em 0;
}
.serverlet_content .settings-box input[type="text"],
.serverlet_content .settings-box input[type="password"]{
    font-size: 0.85em;
    padding: 0.85em 1.5em;
    height: auto;
    width:100%;
    display:inline-block;
}
.serverlet_content .settings-box label{
    font-size: 0.85em;
    margin: 0.5em 0 0.5em 0;
    display:block;
}
.serverlet_content .settings-box input[type="text"]:focus,
.serverlet_content .settings-box input[type="password"]:focus,
.serverlet_content .settings-box input[type="submit"]:focus{
    box-shadow: none;
}