html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
	outline: none;
	-webkit-tap-highlight-color: rgba(255,255,255,0);
}
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}


button::-moz-focus-inner {
  border: 0;
}
:focus {outline:none !important;}

body{
	background: #f8f8f8;
	font-family: Roboto, "Segoe UI", system-ui, Tahoma;
	margin:0;
	color:#222325;
	transition: none;
	overflow-x: hidden;
	overflow-y: scroll;
	scroll-behavior: smooth;
}
.loader{
	position: absolute;
	left:50%;
	top:30%;
	transform: translate(-50%,-50%);
	width: 70px;
	height: 70px;
	background: transparent url("./img/loader-black.svg") center center no-repeat;
	background-size: contain;
	display:block;
	z-index: 1000;
}