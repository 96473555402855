.error-page{
    position: relative;
    min-height: 77.5vh;
    width:100vw;
}
.error-page h1{
    font-weight:600;
    font-size:6em;
    color:#202020;
    position:absolute;
    left:50%;
    top:calc(40%);
    transform:translate(-50%,-50%);
    text-align: center;
}
.error-page h2{
    font-weight:300;
    font-size:3em;
    color:#202020;
    position:absolute;
    left:50%;
    top:calc(45% + 1em);
    transform:translate(-50%,-50%);
    text-align: center;
}

.error-page p{
    font-weight:300;
    font-size:1.6em;
    color:rgba(0,0,0,0.5);
    position:absolute;
    left:50%;
    top:calc(45% + 4.5em);
    transform:translate(-50%,-50%);
    max-width:520px;
    line-height: 1.4;
    text-align: center;
}
