.serverlets-list{
    margin: 1em 0;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    border:1px solid rgba(0,0,0,0.05);
    border-radius: 10px;
    overflow: hidden;
}
.serverlets-none{
    margin: 1em 0;
    border-radius: 10px;
    overflow: hidden;
    display:block;
    width:100%;
    height:6em;
    border:2px dashed rgba(0,0,0,0.15);
    box-shadow: none !important;
    position: relative;
}

.serverlets-none span{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display:inline-block;
    padding:1em 2em;
    border-radius: 2em;
    color:rgba(0,0,0,0.5);
}

.serverlet-item:last-child{
    border-bottom:1px solid rgba(0,0,0,0);
}
.serverlet-item{
    width:100%;
    margin: 0 0;
    background-color: rgba(255,255,255,1);
    display: block;
    min-height: 4em;
    border-left: 3px solid transparent;
    border-right: 2px solid transparent;
    border-bottom:1px solid rgba(0,0,0,0.1);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0.5em 1.5em 0.5em 4.5em;
    text-decoration: none !important;
    outline: none;
    color:#202020;
}
.serverlet-item:before{
    position: absolute;
    content: '';
    left:1.1em;
    top:50%;
    transform: translate(0,-50%);
    width: 2.5em;
    height: 2.5em;
    border-radius: 50%;
    background-color: rgba(251, 204, 74, 0);
    background-image: url("../../img/chip.svg");
    background-repeat: no-repeat;
    background-size: 70%;
    background-position: center center;
    display: block;
}
.serverlet-item:hover{
    background: rgba(251, 197, 49, 0.01);
    border-left: 3px solid #f6d477;
}
.serverlet-item .status::before{
    position: absolute;
    left:-2em;
    top:-50%;
    content:'';
    display:block;
    height:200%;
    width:1px;
    background: rgba(0,0,0,0.2);
}
.serverlet-item .status{
    position: absolute;
    right:2em;
    top:50%;
    transform: translate(0,-50%);
    border-radius: 0.5em;
    font-size: 0.8em;
    padding:0.25em 0.7em;
}
.serverlet-item .running{
    background:rgba(46,204,113,0);
    color:#196e3d;
    border:1px solid #27ae60;
}
.serverlet-item .title{
    position: relative;
    display:inline-block;
    margin: 0.25em 0 0 0;
    font-weight: 400;
    font-size: 1.1em;
}
.serverlet-item .plan{
    position: relative;
    display:block;
    margin: 0.7em 0 0 0;
    font-weight: 600;
    font-size: 0.7em;
    color:rgba(0,0,0,0.5);
    box-shadow: none;
}
.serverlet-item .info{
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
    right:2em;
    display:inline-block;
    font-weight: 400;
    font-size: 0.85em;
    color:rgba(0,0,0,0.5);
    box-shadow: none;
    padding:0.5em 1em;
    background: #dfdfdf;
    border-radius: 1em;
    box-sizing: border-box;
}

.serverlet-item .inactive{
    color:#c0392b;
    background: rgba(231, 76, 60,0.15);
}
