@media only screen and (min-width: 40em) {
    .serverlet_content{
        position: relative;
        width:calc(100% - 325px);
        min-height: 100vh;
        margin: 0 0 0 325px;
        box-sizing: border-box;
        padding: 0em 2em 1em 2em;
        display:block;
        border-right: 1px solid rgba(0,0,0,0.15);
        background: rgba(255,255,255,0.15);
    }
}
@media only screen and (max-width: 39em) {
    .serverlet_content{
        position: relative;
        width:100%;
        min-height: 100vh;
        margin: 0;
        box-sizing: border-box;
        padding: 0em 1.25em 1em 1.25em;
        display:block;
        border-right: 1px solid rgba(0,0,0,0.15);
        background: rgba(255,255,255,0.15);
    }
}

@media only screen and (min-width: 40em) {
    .settings_content{
        width:calc(100% - 510px) !important;
        max-width: 575px;
        margin: 0 0 0 510px !important;
        padding: 1em 2em 1em 2em !important;
    }
}
@media only screen and (max-width: 39em) {
    .settings_content{
        width:100% !important;
        margin: 0 !important;
        padding: 1em 1.25em 1em 1.25em !important;
    }
}
.settings_content{
    background: #fff;
    border-right: 1px solid rgba(0,0,0,0);
}
.settings_content .section p{
    margin: 0 0 0.75em 0;
    line-height: 1.5;
}
.settings_content .section strong{
    font-weight: 700;
}
.settings_content .section .slash{
    margin: 0 0.25em 0 -0.25em;
    font-size: 1.25em;
}
.serverlet_content .section{
    position: relative;
    margin: 1em 0;
    width: 100%;
    height: auto;
    min-height: 2em;
    box-sizing: border-box;
    display:inline-block;
    vertical-align: middle;
}

.serverlet_content .section-half h1,
.serverlet_content .section h1{
    font-weight: 400;
    font-size: 1.3em;
    display:inline-block;
    width: 100%;
    vertical-align: middle;
}
.serverlet_content .section-half .inner,
.serverlet_content .section .inner{
    margin: 1em 0 0 0;
    border-radius: 9px;
    background: rgba(255,255,255,1);
    box-shadow: 0 0 4px rgba(0,0,0,0.1);
    border:0px solid rgba(0,0,0,0.2);
    box-sizing: border-box;
    width: 100%;
    min-height: 3em;
    display: block;
    overflow: hidden;
}
.serverlet_content .section-half .inner-frame,
.serverlet_content .section .inner-frame{
    margin: 1em 0 0 0;
    border-radius: 10px;
    background: rgba(255,255,255,1);
    border:1px solid rgba(0,0,0,0.06);
    box-shadow: 0 0 5px rgba(0,0,0,0.06);
    #border-left:5px solid #f9efd0;
    box-sizing: border-box;
    width: 100%;
    min-height: 2em;
    display: block;
    overflow: hidden;
}
.serverlet_content .section-half .inner-blank,
.serverlet_content .section .inner-blank{
    margin: 1em 0 0 0;
    border-radius: 9px;
    border:1px solid rgba(0,0,0,0);
    box-sizing: border-box;
    width: 100%;
    min-height: 3em;
    display: block;
}
@media only screen and (min-width: 40em) {
    .serverlet_header{
        width:calc(100% + 3.75em);
        height: 5em;
        position: relative;
        top:0;
        left:-1.7em;
        display:block;
        border-bottom:1px solid rgba(0,0,0,0.1);
        box-sizing: border-box;
        padding: 0 0 0 0;
        margin: 0 0 1em 0;
        vertical-align: center;
        overflow: hidden;
    }
    .serverlet_header:before{
        position: absolute;
        content: '';
        left:2em;
        top:50%;
        transform: translate(0,-50%);
        width: 3.75em;
        height: 3.75em;
        border-radius: 50%;
        border:1px solid rgba(0,0,0,0);
        background-color: rgba(255,255,255, 0);
        background-image: url("../img/loader-black.svg");
        background-repeat: no-repeat;
        background-size: 50%;
        background-position: center center;
        display: block;
    }
    .serverlet_header .title{
        position: relative;
        height: 100%;
        width: auto;
        display:inline-block;
        padding: 1.5em 2em 1.5em 5em;
        box-sizing: border-box;
        text-decoration: none;
        color:#000;
        font-weight: 400;
        font-size: 1.3em;
    }
    .serverlet_header .actions{
        position: absolute;
        right:2em;
        top:50%;
        transform: translate(0,-50%);
    }
}

@media only screen and (max-width: 39em) {
    .serverlet_header {
        width: 100%;
        height: 5em;
        position: relative;
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.15);
        box-sizing: border-box;
        vertical-align: center;
        overflow: hidden;
    }
    .serverlet_header .title{
        position: relative;
        height: 100%;
        width: 100%;
        max-width: calc(100% - 8.5em);
        display:inline-block;
        padding: 1.5em 0 1.5em 0em;
        box-sizing: border-box;
        text-decoration: none;
        color:#000;
        font-weight: 400;
        font-size: 1.25em;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
    .serverlet_header .actions{
        position: absolute;
        right:0;
        top:50%;
        transform: translate(0,-50%);
    }
}

.serverlet_header .actions button{
    position: absolute;
    min-width: 3em;
    width: auto;
    outline: none;
    margin:0 0 0 0.8em;
    border-radius: 2em;
    font-family: "Roboto", Verdana, Geneva, Tahoma, sans-serif;
    font-size: 0.9em;
    font-weight: 400;
    padding: 0.45em 1em;
    border:1px solid rgba(0,0,0,0.3);
    background: transparent;
    cursor: pointer;
    right:6.5em;
    top:50%;
    transform: translate(0,-50%);
}
.serverlet_header .actions button:hover{
    background: #dde0e2;
    border:1px solid rgba(0,0,0,0);
}
.serverlet_header .actions .start{
    color:#27ae60;
    border:1px solid #27ae60;
}
.serverlet_header .actions .stop{
    color:#c0392b;
    border:1px solid #c0392b;
}
.serverlet_header .actions .restart{
    color:#202020;
    background: rgba(255,255,255,1);
    border:1px solid rgba(0,0,0,0.2);
}

@media only screen and (min-width: 40em) {
    .settings_sidemenu{
        margin: 0 0 0 250px !important;
    }
    .serverlet_sidemenu{
        width:260px;
        height: 100vh;
        position: fixed;
        top: 75px;
        display:block;
        margin: 0 0 0 70px;
        box-sizing: border-box;
        padding: 1.5em 0 0 0;
        background:rgba(255,255,255,0.5);
        border-right: 1px solid rgba(0,0,0,0.07);
        transition: all 0.5s linear;
        overflow: hidden;
        z-index:400;
        box-shadow: 0 0 8px rgba(0,0,0,0.07);
    }
    .serverlet_sidemenu ul{
        width:100%;
        list-style-type: none;
        margin: 0.25em 0 0 0;
    }
    .serverlet_sidemenu ul .delimiter{
        display:block;
        position: relative;
        width:100%;
        height:1px;
        background: rgba(0,0,0,0.2);
    }
    .serverlet_sidemenu li{
        width:100%;
        display:block;
        height:auto;
        min-height: 1em;
        border-bottom: 0px solid red;
        padding: 0.25em 1em;
        box-sizing: border-box;
    }
    .serverlet_sidemenu .title{
        margin: 0em 0 0.2em 0em;
        font-size: 1.3em;
    }
    .serverlet_sidemenu li a{
        width:100%;
        height: auto;
        min-height: 2em;
        padding: 0.8em 1em 0.8em 3.5em;
        background-color: rgba(0,0,0,0.0);
        text-decoration: none;
        display:block;
        box-sizing: border-box;
        color:rgba(0,0,0,0.7);
        border-radius:8px;
        border:1px solid rgba(0,0,0,0);
        transition: all 0.3s;
        position: relative;
        font-size: 0.9em;
        font-weight: 400;
        font-family: "Roboto", Verdana, Geneva, Tahoma, sans-serif;
        overflow: hidden;
    }
    .serverlet_sidemenu li a:hover{
        background-color: rgba(0,0,0,0.02);
        color:#202020;
        border:1px solid rgba(0,0,0,0.5);
    }
    .serverlet_sidemenu li .active{
        background-color: #dde0e2;
        color:#000;
    }
    .serverlet_sidemenu li .active:hover{
        background-color: #dde0e2;
        color:#000;
        border:1px solid rgba(0,0,0,0.5);
    }
}

@media only screen and (max-width: 39em) {
    .serverlet_sidemenu{
        width:100%;
        height: auto;
        position: relative;
        top: 0;
        display:block;
        margin: 0;
        box-sizing: border-box;
        padding: 0.5em 0.5em;
        background:rgba(255,255,255,0.5);
        transition: all 0.5s linear;
        overflow: scroll;
        z-index:400;
        border-bottom:1px solid rgba(0,0,0,0.1);
        border-right:1px solid rgba(0,0,0,0.15);
        box-shadow: inset 0 -10px 10px -10px rgba(0,0,0,0.1);
    }
    .serverlet_sidemenu ul{
        list-style-type: none;
        margin: 0.25em 0 0 0;
        white-space: nowrap;
    }
    .serverlet_sidemenu li{
        width:auto;
        display:inline-block;
        height:auto;
        min-height: 1em;
        border-bottom: 0px solid red;
        padding: 0.25em 0.5em;
        box-sizing: border-box;
    }
    .serverlet_sidemenu .title{
        margin: 0em 0 0.2em 0em;
        font-size: 1.3em;
    }
    .serverlet_sidemenu li a{
        width:100%;
        height: auto;
        min-height: 2em;
        padding: 0.8em 1em 0.8em 3.5em;
        background-color: rgba(0,0,0,0.0);
        text-decoration: none;
        display:block;
        box-sizing: border-box;
        color:rgba(0,0,0,0.7);
        border-radius:8px;
        border:1px solid rgba(0,0,0,0);
        transition: all 0.3s;
        position: relative;
        font-size: 0.9em;
        font-weight: 400;
        font-family: "Roboto", Verdana, Geneva, Tahoma, sans-serif;
        overflow: hidden;
    }
    .serverlet_sidemenu li a:hover,
    .serverlet_sidemenu li a:focus{
        background-color: rgba(0,0,0,0.02);
        color:#202020;
    }
    .serverlet_sidemenu li .active{
        background-color: #dde0e2;
        color:#000;
    }
    .serverlet_sidemenu li .active:hover,
    .serverlet_sidemenu li .active:focus{
        background-color: #dde0e2;
        color:#000;
    }
}

.serverlet_sidemenu li a:after{
    content:'';
    position: absolute;
    left:0.5em;
    top:0.35em;
    display:block;
    border-radius: 50%;
    width:1.9em;
    height:1.9em;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.1);
    background-repeat: no-repeat;
    background-size: 0.8em;
    background-position: center center;
    box-shadow: 0 0 3px rgba(0,0,0,0);
}
.serverlet_sidemenu li .back-icon:after{
    background-image: url("../img/back.svg");
}
.serverlet_sidemenu li .options-icon:after{
    background-image: url("../img/options.svg");
}
.serverlet_sidemenu li .domains-icon:after{
    background-image: url("../img/link.svg");
}
.serverlet_sidemenu li .users-icon:after{
    background-image: url("../img/users.svg");
}
.serverlet_sidemenu li .databases-icon:after{
    background-image: url("../img/databases.svg");
}
.serverlet_sidemenu li .software-icon:after{
    background-image: url("../img/software.svg");
}
.serverlet_sidemenu li .graphs-icon:after{
    background-image: url("../img/graphs.svg");
}
.serverlet_sidemenu li .usage-icon:after{
    background-image: url("../img/graphs.svg");
}
.serverlet_sidemenu li .cron-icon:after{
    background-image: url("../img/cron.svg");
}
.serverlet_sidemenu li .overview-icon:after{
    background-image: url("../img/overview.svg");
}

.serverlet_sidemenu li .user-icon:after{
    background-image: url("../img/email.svg");
}
.serverlet_sidemenu li .password-icon:after{
    background-image: url("../img/password.svg");
}
.serverlet_sidemenu li .contact-icon:after{
    background-image: url("../img/invoice.svg");
}
.serverlet_sidemenu li .devices-icon:after{
    background-image: url("../img/devices.svg");
}
.serverlet_sidemenu li .logs-icon:after{
    background-image: url("../img/logs.svg");
}
.serverlet_sidemenu li .limits-icon:after{
    background-image: url("../img/limits.svg");
}
.serverlet_sidemenu li .close-account-icon{
    color:#c0392b;
}
.serverlet_sidemenu li .close-account-icon:after{
    background-image: url("../img/close.svg");
}
.serverlet_sidemenu li .information-icon:after{
    background-image: url("../img/information.svg");
}
.serverlet_content .section-half .inner-frame table,
.serverlet_content .section .inner-frame table{
    position: relative;
    width:100%;
    text-align: left;
}
.serverlet_content .section-half .inner-frame table .right,
.serverlet_content .section .inner-frame table .right{
    position: relative !important;
    width:40px !important;
    text-align: right;
    padding: 0 !important;
}
.serverlet_content .section .users-list,
.serverlet_content .section .domains-list,
.serverlet_content .section .software-list{
    background: rgba(255,255,255,1);
    border: 1px solid rgba(0,0,0,0.07) !important;
    font-size: 1.05em;
}
.serverlet_content .section .edit-tr{
    height:6.75em;
    position: relative;
    animation: edit-tr-show 0.5s;
    z-index:10 !important;
}
@keyframes edit-tr-show {
    0%{
        height: 0;
        font-size:0;
    }
    100%{
        height:6.75em;
    }
}
.serverlet_content .section .edit-box{
    position: absolute;
    top:0;
    left:0;
    width:100%;
    height: 100%;
    background: #f9f9f9;
    display:block;
    box-sizing: border-box;
    padding: 1em 1em;
    border-left:2px solid #fbc531;
}


.serverlet_content .section .user-add-box{
    padding:1.5em 1em 1em 1em;
    font-size: 1.05em;
    height:auto;
    animation: none;
    border:1px solid rgba(0,0,0,0.2);
}

.serverlet_content .section .edit-tr input[type="submit"] {
    margin:0.5em 0 0 0;
}
.serverlet_content .section .edit-tr input[type="text"],
.serverlet_content .section .edit-tr input[type="password"]{
    font-size: 0.85em;
    padding: 0.75em 1.5em;
    height: auto;
    max-width: 300px;
    display:inline-block;
}
.serverlet_content .section .edit-tr label{
    font-size: 0.85em;
    margin: 0 2em 0 0.5em;
    min-width: 6em;
    display:inline-block;
}
.serverlet_content .section .edit-tr input[type="text"]:focus,
.serverlet_content .section .edit-tr input[type="password"]:focus,
.serverlet_content .section .edit-tr input[type="submit"]:focus{
    box-shadow: none;
}

.serverlet_content .section .users-list table td,
.serverlet_content .section .domains-list table td,
.serverlet_content .section .software-list table td{
    border-right: none !important;
    font-size: 0.9em !important;
}
.serverlet_content .section .users-list table tbody td:first-child
{
    width: auto !important;
}
.serverlet_content .section .users-list table tr,
.serverlet_content .section .domains-list table tr,
.serverlet_content .section .software-list table tr{
    position: relative;
    border-bottom: 1px solid rgba(0,0,0,0.05) !important;
    z-index:100;
}
.serverlet_content .section .software-list td:nth-child(3){
    text-align: left;
}
.serverlet_content .section .software-list table .icon{
    padding:0;
    width:50px !important;
}
.serverlet_content .section .software-list table .icon span{
    height: 40px;
    width: 40px;
    display:block;
    top:50%;
    transform: translate(0,-50%);
    left:1em;
    position: absolute;
    border:1px solid rgba(0,0,0,0.1);
    border-radius: 10px;
}
.serverlet_content .section .software-list .state-running span,
.serverlet_content .section .software-list .state-stopped span,
.serverlet_content .section .software-list .state-enabled span,
.serverlet_content .section .software-list .state-disabled span {
    position: relative;
    left:-1.1em;
    border-radius: 1em;
    font-size: 0.9em;
    display:inline-block;
    padding:0.25em 0.75em;
}

.serverlet_content .section .software-list .state-running span{
    background: rgba(39, 174, 96, 0.05);
    border: 1px solid rgba(32, 142, 78, 0.2);
    color: #208e4e;
}
.serverlet_content .section .software-list .state-stopped span{
    background: rgba(192, 57, 43, 0.05);
    border: 1px solid rgba(192, 57, 43, 0.2);
    color: rgba(192, 57, 43, 1);
}
.serverlet_content .section .software-list .state-enabled span{
    background: rgba(41, 128, 185, 0.1);
    border: 1px solid rgba(41, 128, 185, 0.2);
    color: #226a99;
}
.serverlet_content .section .software-list .state-disabled span{
    background: rgba(243, 156, 18, 0.1);
    border: 1px solid rgba(192, 122, 10, 0.3);
    color: rgba(192, 122, 10, 1);
}
.serverlet_content .section .software-list table .icon img{
    position: absolute;
    top:15%;
    left:15%;
    width:70%;
    height:70%;
    object-fit: contain;
}
.serverlet_content .section .users-list table tr:hover,
.serverlet_content .section .domains-list table tr:hover,
.serverlet_content .section .software-list table tr:hover{
    background: rgba(0,0,0,0.05);
}
.serverlet_content .section-half table .left-id,
.serverlet_content .section table .left-id{
    width: 20px !important;
    text-align: left;
    border-right: none !important;
}
.serverlet_content .section-half table .left-id i,
.serverlet_content .section table .left-id i{
    display:inline-block;
    background: rgba(0,0,0,0.1);
    padding:0.25em 0.5em;
    border-radius: 0.5em;
}
.serverlet_content .section-half .inner-frame table tr,
.serverlet_content .section .inner-frame table tr{
    border-bottom:1px solid rgba(0,0,0,0);
}
.serverlet_content .section table tr:last-child{
    border-bottom: none;
}
.serverlet_content .section-half .inner-frame table thead tr,
.serverlet_content .section .inner-frame table thead tr{
    border-bottom:1px solid rgba(0,0,0,0.1) !important;
    font-weight: 400;
    font-size: 0.95em;
    color:rgba(0,0,0,1);
    padding: 0.6em 1.75em !important;
}
.serverlet_content .section-half .inner-frame table thead td,
.serverlet_content .section .inner-frame table thead td{
    padding: 0.8em 1.5em;
}
.serverlet_content .section-half .inner-frame table tr:last-child,
.serverlet_content .section .inner-frame table tr:last-child{
    border-bottom:1px solid rgba(0,0,0,0);
}
.serverlet_content .section-half .inner-frame table tbody td:first-child,
.serverlet_content .section .inner-frame table tbody td:first-child{
    width: 150px;
    border-right:1px solid rgba(0,0,0,0.1);
    font-weight: 400;
    font-size: 0.9em;
    color:rgba(0,0,0,0.8);
}

.serverlet_content .section-half .inner-frame table tbody td,
.serverlet_content .section .inner-frame table tbody td{
    padding: 0.9em 1.5em;
    font-weight: 400;
    font-size: 1em;
    position: relative;
}

.serverlet_content .switch {
    position: absolute;
    right: 0;
    top:50%;
    transform: translate(0,-50%);
    display: inline-block;
    width: 75px;
    height: 2em;
}

.serverlet_content .switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.serverlet_content .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(192, 57, 43, 0.2);
    border:1px solid rgba(192, 57, 43, 1);
    -webkit-transition: all .4s;
    transition: all .4s;
}

.serverlet_content .slider .label {
    position: absolute;
    top: 50%;
    transform: translate(0,-50%);
    font-weight: 600;
    font-size: 0.8em;
    text-transform: uppercase;
    transition: all 0.4s;
}

.serverlet_content .switch input:checked + .slider .label {
    color:#1c7e45 !important;
    left: 1em !important;
}
.serverlet_content .switch input + .slider .label {
    color:rgba(192, 57, 43, 1);
    left: 2.8em;
}


.serverlet_content .slider:before {
    position: absolute;
    content: "";
    height: 1.5em;
    width: 1.5em;
    left: 4px;
    bottom: 3px;
    -webkit-transition: .4s;
    transition: .4s;
}

.serverlet_content input:checked + .slider {
    background-color: rgba(46, 204, 113, 0.3);
    border:1px solid #27ae60;
}

.serverlet_content input:focus + .slider {
}

.serverlet_content input + .slider:before {
    background:rgba(192, 57, 43, 1);
}
.serverlet_content input:checked + .slider:before {
    -webkit-transform: translateX(42px);
    -ms-transform: translateX(42px);
    transform: translateX(42px);
    background-color: rgba(39, 174, 96,1.0);
}

.serverlet_content .slider.round {
    border-radius: 34px;
}

.serverlet_content .slider.round:before {
    border-radius: 50%;
}

.serverlet_content .copy-button{
    position: absolute;
    height:2em;
    width:3.5em;
    display:inline-block;
    right:1.5em;
    top:0.5em;
    outline:none;
    border-radius: 1em;
    border:1px solid rgba(0,0,0,0.3);
    background: rgba(0,0,0,0) url("../img/copy.svg") center center no-repeat;
    background-size: 1em;
    cursor: pointer;
    margin: 0;
}
.serverlet_content .copy-button:hover{
    background: #fbc531 url("../img/copy.svg") center center no-repeat;
    background-size: 1em;
    border:1px solid #fbc531;
}
.serverlet_content .edit-button{
    position: absolute;
    height:2em;
    width:auto;
    min-width: 5em;
    padding: 0.3em 0.7em;
    display:inline-block;
    right:4.5em;
    top:0.6em;
    outline:none;
    border-radius: 0.5em;
    border:1px solid rgba(0,0,0,0.3);
    background: rgba(0,0,0,0);
    cursor: pointer;
    margin: 0;
    color:rgba(0,0,0,0.75);
}
.serverlet_content .action-button,
.serverlet_content .action2-button,
.serverlet_content .action3-button{
    position: absolute;
    height:25px;
    width:25px;
    display:inline-block;
    top:12px;
    outline:none;
    border-radius: 0.7em;
    cursor: pointer;
    margin: 0;
    background-size: 50%;
    background-position: center center;
    background-repeat: no-repeat;
    box-sizing: border-box;
}
.serverlet_content .action-button.loading-button,
.serverlet_content .action2-button.loading-button,
.serverlet_content .action3-button.loading-button {
    background-image: url("../img/loader-circle-white.svg") !important;
}
.serverlet_content .action-button:hover,
.serverlet_content .action2-button:hover,
.serverlet_content .action3-button:hover{
    background-color: #969696;
}
.serverlet_content .action-button{
    right:7.5em;
}
.serverlet_content .action2-button{
    right:132.5px;
}
.serverlet_content .action3-button{
    right:165px;
}
.serverlet_content .start-button{
    border:none;
    background-color: rgba(39, 174, 96, 1);
    background-image: url("../img/start.svg");
    color:#fff;
}
.serverlet_content .stop-button{
    border:none;
    background-color: #e74c3c;
    background-image: url("../img/stop.svg");
    color:#fff;
}
.serverlet_content .restart-button{
    border:none;
    background-color: #f39c12;
    background-image: url("../img/restart.svg");

    color:#fff;
}
.serverlet_content .reload-button{
    border:none;
    background-color: #f9ca24;
    background-image: url("../img/reload.svg");
    color:#fff;
}
.serverlet_content .enable-button{
    position: absolute;
    height:2em;
    width:auto;
    min-width: 5em;
    padding: 0.3em 0.7em;
    display:inline-block;
    right:1.5em;
    top:12px;
    outline:none;
    border-radius: 1em;
    border:1px solid rgba(0,0,0,0.3);
    cursor: pointer;
    margin: 0;
    color:rgba(0,0,0,0.75);
    background-color: rgba(0,0,0,0);
    transition: all 0.5s;
}
.serverlet_content .enable-button.loading-button{
    background-color: #fbc531;
    border:1px solid transparent;
}
.serverlet_content .enable-button.loading-button:after{
    position: absolute;
    content:'';
    top:2px;
    left:2px;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    display:block;
    background-color: #fbc531;
    background-size: auto 80%;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url("../img/loader-circle-black.svg") !important;
    box-sizing: border-box;
    border-radius: 0.5em;
}
.serverlet_content .enable-button:hover{
    background-color: #fbc531;
    border:1px solid transparent;
}

.serverlet_content .edit-button:hover{
    background: #fbc531;
    border:1px solid #fbc531;
}
.serverlet_content .delete-button{
    position: absolute;
    height:2em;
    width:2em;
    display:inline-block;
    padding: 0.15em 0.7em;
    right:1.5em;
    top:0.6em;
    outline:none;
    border-radius: 0.5em;
    border:1px solid #c0392b;
    background: transparent url("../img/delete.svg") center center no-repeat;
    background-size: 1em;
    cursor: pointer;
    color:#c0392b;
    z-index:100;
}
.serverlet_content .delete-button:hover{
    background: #c0392b url("../img/delete-white.svg") center center no-repeat;
    background-size: 1em;
    border:1px solid #c0392b;
    color:#fff;
}
.serverlet_content .gen-button{
    position: absolute;
    height:2em;
    width:auto;
    display:inline-block;
    right:1.5em;
    top:0.6em;
    outline:none;
    border-radius: 1em;
    border:1px solid rgba(0,0,0,0.3);
    background: rgba(0,0,0,0);
    cursor: pointer;
    text-decoration: none;
    color: rgba(0,0,0,0.8);
    box-sizing: border-box;
    padding: 0.45em 0.7em;
    font-size: 0.8em;
}
.serverlet_content .gen-button:hover{
    background: #fbc531;
    border:1px solid #fbc531;
}
.serverlet_content .section-half .padded,
.serverlet_content .section .padded{
    padding: 0.1em 0 0 2em;
    box-sizing: border-box;
}
.serverlet_content .section-half .count,
.serverlet_content .section .count{
    position: absolute;
    left:0;
    top:0;
    font-weight: 400;
    font-size: 1em;
    display:inline-block;
    background:rgba(0,0,0,0.1);
    border:1px solid rgba(0,0,0,0);
    border-radius: 6px;
    overflow: hidden;
    padding: 0.25em 0.5em;
    box-sizing: border-box;
}

.serverlet_content .inner-none{
    margin: 1em 0;
    border-radius: 10px;
    overflow: hidden;
    display:block;
    width:100%;
    height:6em;
    border:2px dashed rgba(0,0,0,0.15);
    box-shadow: none !important;
    position: relative;
}

.serverlet_content .inner-none span{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display:inline-block;
    padding:1em 2em;
    border-radius: 2em;
    color:rgba(0,0,0,0.5);
}

.inner-input .list-text{
    position: relative;
    display:inline-block !important;
    width:calc(100% - 100px) !important;
    border-radius: 8px 0 0 8px !important;
    top:1px !important;
}
.inner-input .list-text:focus ~ .list-submit{
    background: #fbc531 !important;
    border:1px solid #fbc531 !important;
    border-left:none !important;
}
.inner-input .list-submit{
    position: relative;
    display:inline-block !important;
    width:100px !important;
    height:52px !important;
    border-radius: 0 8px 8px 0 !important;
    box-sizing: border-box !important;
    top:0 !important;
    border:1px solid rgba(0,0,0,0.3) !important;
    border-left:none !important;
}
.inner-input .list-submit:hover{
    background: #fbc531 !important;
    border:1px solid #fbc531 !important;
    border-left:none !important;
}
.inner-input .list-submit:focus{
    border:1px solid rgba(0,0,0,0.3) !important;
    border-left:none !important;
    background: rgba(0,0,0,0.1) !important;
}
.inner-input .info{
    position: relative;
    display:inline-block;
    font-size: 0.7em;
    color:rgba(0,0,0,0.5);
    top:-0.5em;
}

.serverlet_content table .no-cert{
    position: absolute;
    height:1em;
    width:1em;
    display:block;
    padding: 0.15em 0.7em;
    right:12em;
    top:0.65em;
    background: url("../img/no-cert.svg") center center no-repeat;
    background-size: contain;
    opacity:0.15;
}

.serverlet_content table .cert{
    position: absolute;
    height:1em;
    width:1em;
    display:block;
    padding: 0.15em 0.7em;
    right:12em;
    top:0.65em;
    background: url("../img/cert.svg") center center no-repeat;
    background-size: contain;
}
@media only screen and (min-width: 40em) {
    .serverlet_content .resources{
        width:100%;
        display:flex;
        justify-content: space-between;
    }
    .serverlet_content .resource{
        width:calc((100% / 3) - 1em);
    }
}
@media only screen and (max-width: 39em) {
    .serverlet_content .resource{
        width:100%;
        margin: 0 0 1em 0;
    }
}
.serverlet_content .resource{
    box-sizing: border-box;
    min-height: 5em;
    height: auto;
    display:inline-block;
    border:1px solid rgba(0,0,0,0.07);
    box-shadow: 0 0 7px rgba(0,0,0,0.05);
    border-radius: 10px;
    text-align:right;
    position: relative;
    background-color: #fff;
    background-size: auto 2.5em;
    background-repeat: no-repeat;
    background-position: left 1.5em top 1em;
    overflow: hidden;
}
.serverlet_content .resource:after{
    position: absolute;
    content: '';
    top:0;
    left:0;
    height: 100%;
    width: 5.5em;
    border-right: 1px solid rgba(0,0,0,0);
    background: rgba(0,0,0,0.0);
}
.serverlet_content .resource .usage{
    box-sizing: border-box;
    width: 100%;
    border-top:1px solid rgba(0,0,0,0.07);
    padding: 0.75em 1.25em;
    margin: 1em 0 0 0;
    position: relative;
}
.serverlet_content .resource .usage progress {
    width: 100%;
    display: inline-block;
    padding: 0px;
    background: rgba(0,0,0,0.05);
    border:1px solid rgba(0,0,0,0.05);
    border-radius: 14px;
    overflow: hidden;
}
.serverlet_content .resource .usage .label{
    position: absolute;
    right:0.25em;
    top:42.5%;
    transform: translate(0,-50%);
    min-width: 4em;
    text-align: center;
    font-size: 0.7em;
    border-radius: 1em;
    color:rgba(0,0,0,0.5)
}
.serverlet_content .resource .usage progress[value]::-webkit-progress-bar{
    background: rgba(0,0,0,0.05);
    padding: 0 1px 1px 1px;
}

.serverlet_content .resource .usage progress[value]::-webkit-progress-value{
    border-radius: 12px;
    background: #fbc531;
}
.serverlet_content .resource .usage progress::-moz-progress-bar {
    border-radius: 12px;
    background: #fbc531;
}

.serverlet_content .resource h2{
    margin: 0.7em 1em 0.25em 1em;
    font-size: 1.5em;
}
.serverlet_content .resource span{
    margin: 0.25em 1.65em;
    font-size: 0.95em;
}
.serverlet_content .resource.cpu{
    background-image: url("../img/cpu.svg");
}
.serverlet_content .resource.ram{
    background-image: url("../img/ram.svg");
}
.serverlet_content .resource.storage{
    background-image: url("../img/ssd.svg");
}
