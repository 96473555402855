input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance:textfield;
}
@media only screen and (min-width: 40em) {
    .content .order-form{
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 48em;
        min-height: 100vh;
        box-sizing: border-box;
        padding: 1em 2em;
    }
}
@media only screen and (max-width: 39em) {
    .content .order-form{
        position: relative;
        margin: 0 auto;
        width: 100%;
        max-width: 48em;
        min-height: 100vh;
        box-sizing: border-box;
        padding: 1em 0.5em;
    }
}
.section input[type="number"],
.section-half input[type="number"]{
    background-size: 17px;
    border-radius: 8px;
    padding: 5px 15px;
    margin: 0 0 0.75em 0;
    color: #202020;
    font-size: 17px;
    font-family: 'Roboto', sans-serif;
    font-weight: 400;
    width: calc(100% - 1.1em);
    height: 52px;
    box-sizing: border-box;
    outline-style: none;
    box-shadow: none;
    border:1px solid rgba(0,0,0,0.3);
    display:inline-block;
    background: rgba(255, 255, 255, 0.75) no-repeat 17px 16px;
}

.section .number-input input[type="text"],
.section-half .number-input input[type="text"]{
    background: transparent;
    border-radius: 0;
    width: calc(100% - 104px);
    float:left;
    border:none;
    margin: 0;
}
.section .number-input input[type="text"]:focus,
.section-half .number-input input[type="text"]:focus{
    box-shadow: none;
}
.section .number-input:focus-within,
.section-half .number-input:focus-within{
    box-shadow: 0 0 1px rgba(251, 197, 49, 1);
    border:1px solid rgba(251, 197, 49, 1);
    background: rgba(255, 255, 255, 0.75);
}
.section .number-input,
.section-half .number-input{
    height: 52px;
    border:1px solid rgba(0,0,0,0.3);
    border-radius: 8px;
    position: relative;
    display:inline-block;
    width: calc(100% - 0em);
    box-sizing: border-box;
    vertical-align: bottom;
    padding:0;
}
.section .number-input button,
.section-half .number-input button{
    height:calc(100% + 2px);
    width:52px;
    margin: 0;
    padding:0;
    float:left;
    border:1px solid transparent;
    font-size: 1.2em;
    color:#202020;
    cursor: pointer;
    background: transparent;
    border-left: 1px solid rgba(0,0,0,0.05);
    vertical-align: bottom;
    display:block;
    position: relative;
    top:-1px;
    right:-1px;
    border-radius: 0 8px 8px 0;
}
.section .number-input button:first-child,
.section-half .number-input button:first-child{
    font-size: 1.5em;
    border-right: 1px solid rgba(0,0,0,0.05);
    border-radius: 8px 0 0 8px;
    left:-1px;
}
.section .number-input button:hover,
.section-half .number-input button:hover{
    background: #fbc531;
}
.section .number-input .label,
.section-half .number-input .label{
    position: absolute;
    top:50%;
    transform: translate(0,-50%);
    right:75px;
}
.section p a,
.section-half p a{
    text-decoration: none;
    color:#a47a04;
}
.section p a:hover,
.section-half p a:hover{
    color:#202020;
}
.section .contact-title{
    margin: 0 0 0.75em 0 !important;
    font-size: 1.4em !important;
    font-weight: 400 !important;
}
.section p{
    margin: 0 0 0.4em 0;
}
.section .small{
    margin: 1.1em 1em 0.4em 0;
    font-size: 0.9em;
    text-align: left;
    color:rgba(0,0,0,0.6);
    background: url("../img/order-information.svg") left center no-repeat;
    background-size: 14px;
    padding: 0 0 0 1.8em;
}
.section .contact{
    border:1px solid rgba(0,0,0,0.15);
    box-shadow: 0 0 8px rgba(0,0,0,0);
    border-left:2px solid #fbc531;
    margin: 1em 0 0 0;
    border-radius: 12px;
    background: rgba(255,255,255,0.75);
    box-sizing: border-box;
    padding:1.5em 1.5em 0.9em 1.5em;
    position: relative;
    overflow: visible;
}
.section .contact .edit{
    position: absolute;
    top:1em;
    right: 1em;
    width:35px;
    height: 35px;
    background: rgba(0,0,0,0.05) url("../img/edit.svg") center center no-repeat;
    background-size: 40%;
    border:1px solid rgba(0,0,0,0.15);
    border-radius: 8px;
}
.section .contact .edit:hover{
    background: #f5c02f url("../img/edit.svg") center center no-repeat;
    background-size: 40%;
    border:1px solid rgba(0,0,0,0);
}



.section .contact .other{
    margin: 1em 0 0 0;
}