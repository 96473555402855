.page_alert_container{
    position:fixed;
    left:0;
    top:0;
    background: rgba(0,0,0,0.5);
    width:100%;
    height: 100%;
    display:block;
    z-index:10000;
}

.page_alert{
    position:absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    width:100%;
    max-width: 380px;
    background: #fff url("../../img/warning_icon_black.svg") center top 1.5em no-repeat;
    background-size: 2em;
    height: auto;
    min-height: 100px;
    border-radius: 15px;
    box-shadow: 0 3px 12px rgba(0, 0, 0, 0.03), 0 3px 12px rgba(0, 0, 0, 0.06);
    overflow: hidden;
}

.page_alert h2{
    text-align: center;
    margin: 4em 0 2em 0;
    font-size: 1.1em;
}

.page_alert button{
    position: relative;
    width:50%;
    height: auto;
    padding: 1em 2em;
    box-sizing: border-box;
    border:none;
    outline:none;
    border-top:1px solid rgba(0,0,0,0.3);
    background: #fff;
    font-size: 1.1em;
    font-weight: 400;
    cursor: pointer;
}
.page_alert button:hover{
    background: rgba(0,0,0,0.15) !important;
}
.page_alert button:first-of-type{
    border-right:1px solid rgba(0,0,0,0.3);
}
