.page_message{
    position:fixed;
    bottom:0;
    left:0;
    box-sizing: border-box;
    width:100%;
    z-index:10000;
    transform: translate(0,5em);
    font-weight: 400;
    font-size: 1.05em;
    animation: messageSlide 6s;
    line-height: 1.3;
}
@media only screen and (min-width: 40em) {
    .page_message{
        background-size: 20px;
        background-position: 4em center;
        background-repeat: no-repeat;
        padding: 0.8em 6.5em 0.9em 6.5em;
    }
}
@media only screen and (max-width: 39em) {
    .page_message{
        background-size: 20px;
        background-position: 1em center;
        background-repeat: no-repeat;
        padding: 0.8em 1.5em 0.9em 3.5em;
        font-size: 1em;
    }
}
.page_message span{
    z-index:1000;
}
.page_message:after{
    content:'';
    position: absolute;
    bottom:-1px;
    left:0;
    height: 0.25em;
    background: rgba(0,0,0,0.5);
    transform: translate(0,0);
    width: 100%;
    display:block;
    animation: messageTimeout 5.5s linear;
}
.hide_message{
    display:none;
}
.info_message{
    background-color:#fde091;
    background-image: url("../../img/info_icon.svg");
    color:#000;
}
.warning_message{
    background-color: #e67e22;
    background-image: url("../../img/warning_icon.svg");
    color:#fff;
}
.error_message{
    background-color: #c0392b;
    background-image: url("../../img/error_icon.svg");
    color:#fff;
}
.success_message{
    background-color: #27ae60;
    background-image: url("../../img/success_icon.svg");
    color:#fff;
}
@keyframes messageSlide {
    0%{
        transform: translate(0,5em);
    }
    10% {
        transform: translate(0,0);
    }
    90% {
        transform: translate(0,0);
    }
    100%{
        transform: translate(0,5em);
    }
}

@keyframes messageTimeout {
    0%{
        transform: translate(0,0);
    }
    100%{
        transform: translate(-100vw,0);
    }
}