.domains-dns-list{
    margin: 1em 0;
    box-shadow: 0 0 8px rgba(0,0,0,0.05);
    border:1px solid rgba(0,0,0,0.05);
    border-radius: 10px;
    overflow: hidden;
}
.domains-dns-none{
    margin: 1em 0;
    border-radius: 10px;
    overflow: hidden;
    display:block;
    width:100%;
    height:6em;
    border:2px dashed rgba(0,0,0,0.15);
    box-shadow: none !important;
    position: relative;
}

.domains-dns-none span{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    display:inline-block;
    padding:1em 2em;
    border-radius: 2em;
    color:rgba(0,0,0,0.5);
}

.domains-dns-item:last-child{
    border-bottom:1px solid rgba(0,0,0,0);
}
.domains-dns-item{
    width:100%;
    margin: 0 0;
    background-color: rgba(255,255,255,1);
    display: block;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom:1px solid rgba(0,0,0,0.15);
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0.6em 1.5em 0.8em 1.5em;
    text-decoration: none !important;
    outline: none;
    color:#202020;
}

.domains-dns-item:hover{
    background: rgba(251, 197, 49, 0.01);
    border-left: 5px solid #f6d477;
}
.domains-dns-item .title{
    position: relative;
    display:inline-block;
    margin: 0.25em 0 0 0;
    font-weight: 400;
    font-size: 1.1em;
}
.domains-dns-item  .updated{
    position: absolute;
    top:0.25em;
    right:1.1em;
    display:block;
    margin: 0.7em 0 0 0;
    font-weight: 400;
    font-size: 0.7em;
    color:rgba(0,0,0,0.5);
    box-shadow: none;
}
.domains-dns-item  .updated .label{
    position: relative;
    display:block;
    text-align: right;
    margin: 0 0 0.25em 0;
    font-weight: 600;
}