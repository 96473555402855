header{
	top:0;
	display:block;
	width:100%;
	height:75px;
	position: fixed;
	padding:0;
	background: #fff;
	z-index: 4000;
	transition: background 0.3s, border 0.3s, padding .2s !important;
	box-sizing: border-box;
	#border-bottom:1px solid rgba(0,0,0,0.2);
	box-shadow: 0 1px 12px rgba(0,0,0,0.03), 0 1px 8px rgba(0,0,0,0.06);
}

header:after{
	content:'';
	position: absolute;
	top:0;
	left:0;
	height: 1.5px;
	width: 100%;
	background: #fbc531;
	background: linear-gradient(to right, #ffcc2f, #fbc531);
}
header:not(:stuck) {
	top:0 !important;
	background:#1e1f21 !important;
	box-shadow:0 8px 15px rgba(0,0,0,0.25) !important;
}

header .credit{
	position:absolute;
	top:0;
	left:250px;
	height:100%;
	display:block;
	box-sizing: border-box;
	border-left: 1px solid rgba(0,0,0,0.1);
	border-right: 1px solid rgba(0,0,0,0.1);
	text-align: left;
	padding: 1.1em 8em 1em 1em;
	min-width: 265px;
	width: auto;
}
header .credit .title{
	position: relative;
	font-weight: 600;
	font-size: 0.7em;
	text-transform: uppercase;
	margin: 0 0 0.5em 0;
	color:rgba(0,0,0,0.4);
}
header .credit .value{
	position: relative;
	font-weight: 400;
	font-size: 1.4em;
}
header .credit .value i{
	color:rgba(0,0,0,0.8);
}
header .credit .add{
	position: absolute;
	top:50%;
	transform: translate(0,-50%);
	right:1em;
	text-decoration: none;
	display:inline-block;
	background:transparent;
	border-radius: 2em;
	border:1px solid rgba(0,0,0,0.3);
	color:#1c1a1b;
	font-size: 1em;
	font-weight: 400;
	padding: 0.5em 1em;
}
header .credit .add:hover{
	background:#fbc531;
	border:1px solid #fbc531;
}
header .right{
	position: absolute;
	top:50%;
	transform: translate(0,-50%);
	box-sizing: border-box;
}
@media only screen and (min-width: 40em) {
	header .right{
		right:2em;
	}
}
@media only screen and (max-width: 39em) {
	header .right{
		right:1.25em;
	}
}
header .right .user{
	position: relative;
	display:block;
	background:rgba(0,0,0,0);
	height:45px;
	padding:0.9em 2em 0.9em 4em;
	border-radius: 2em;
	box-sizing: border-box;
	border:1px solid rgba(0,0,0,0.25);
	cursor: pointer;
}
header .right .user:hover, header .right .user-focused{
	background:rgba(0,0,0,0.1);
}
header .right .user .avatar{
	position: absolute;
	height:calc(45px - 2px);
	width:calc(45px - 2px);
	border-radius: 50%;
	background: rgba(0,0,0,0.1);
	left:0;
	top:0;
	box-sizing: border-box;
	text-align: center;
	line-height: 45px;
	font-size: 1.2em;
	display:block;
	overflow: hidden;
}
header .right .panel .hide{
	display:none;
	animation: menu-hide 0.3s;
}
header .right .panel .show{
	display:block;
	animation: menu-show 0.3s;
}
@keyframes menu-hide {
	0% {
		top:54px;
		opacity:1;
		display:block !important;
	}
	100% {
		top:40px;
		opacity:0;
		display:block !important;
	}
}
@keyframes menu-show {
	0% {
		top:40px;
		opacity:0;
	}
	100% {
		top:54px;
		opacity:1;
	}
}
header .right .panel .menu{
	position: absolute;
	top:54px;
	right:0;
	width:100%;
	height: auto;
	background: #fff;
	box-sizing: border-box;
	border-radius: 10px;
	filter: drop-shadow(0 0 0.25rem rgba(0,0,0,0.15));
	padding:0.3em;
	border:1px solid rgba(0,0,0,0.25);
	transition: all 0.5s;
}
header .right .panel .menu::before {
	content: "";
	position: absolute;
	top: -10px;
	left: 50%;
	transform: translate(-50%);
	width: 0;
	height: 0;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	border-bottom: 10px solid rgba(0,0,0,0.25);
}
header .right .panel .menu::after {
	content: "";
	position: absolute;
	top: -9px;
	left: 50%;
	transform: translate(-50%);
	width: 0;
	height: 0;
	border-right: 8px solid transparent;
	border-left: 8px solid transparent;
	border-bottom: 10px solid #fff;
}
header .right .panel .menu li{
	padding: 0.53em 0;
	border-bottom:1px solid rgba(0,0,0,0.15);
}
header .right .panel .menu li:first-child{
	padding: 0 0 0.3em 0;
}
header .right .panel .menu li:last-child{
	padding: 0.3em 0 0 0;
	border-bottom:1px solid rgba(0,0,0,0);
}
header .right .panel .menu a{
	display:block;
	position: relative;
	text-decoration: none;
	color:#202020;
	padding:0.9em 1em 0.9em 3em;
	box-sizing: border-box;
	border-radius: 10px;
	background-repeat: no-repeat;
	background-position: 1em center;
	background-size: 15px;
}
header .right .panel .menu a:hover{
	background-color: rgba(0,0,0,0.1);
}
header .right .panel .menu .settings{
	background-image:url("../../img/settings.svg");
}
header .right .panel .menu .logout{
	background-image:url("../../img/logout.svg");
}

header .menu-close button,
header .menu-open button{
	background-color: #fff;
	background-image: url("../../img/menu.svg");
	background-position: center right 1em;
	background-repeat: no-repeat;
	background-size: auto 60%;
	border: 1px solid rgba(0,0,0,.5);
	border-radius: 1em;
	box-sizing: border-box;
	color: #202020;
	cursor: pointer;
	display: inline-block;
	font-size: 1em;
	font-weight: 400;
	min-width: 50px;
	padding: .45em 3em .35em 1em;
	position: relative;
	text-decoration: none;
}
header .menu-open button {
	background-image: url("../../img/menu.svg");
}
header .menu-close button:hover,
header .menu-open button:hover,
header .menu-close button:focus,
header .menu-open button:focus{
	background-color: rgba(0,0,0,0.1);
	border:1px solid transparent;
}