@media only screen and (min-width: 40em) {
    .sidemenu{
        width:250px;
        height: 100vh;
        position: fixed;
        top: 75px;
        display:block;
        box-sizing: border-box;
        padding: 1.5em 0 0 0;
        background:#f8f8f8;
        box-shadow: 5px 0 8px -5px rgba(0,0,0,0);
        border-right: 1px solid rgba(0,0,0,0.15);
        transition: all 0.5s linear;
        overflow: hidden;
        z-index:500;
    }
    @keyframes sidemenu_smaller {
        0% {
            width:250px;
        }
        100% {
            width:70px;
        }
    }

    .sidemenu-small{
        width:70px;
    }
    .sidemenu-animate{
        animation: sidemenu_smaller 0.5s ease-in;
    }
    .sidemenu-small:hover{
        width:250px;
    }
    .sidemenu-small:hover ul li a{
        width:100%;
        padding: 0.8em 1em 0.8em 3.5em;
    }
    @keyframes sidemenu_a_smaller {
        0% {
            width:100%;
            padding: 0.8em 1em 0.8em 3.5em;
        }
        100% {
            width:0px;
            padding: 0.8em 0em 0.8em 2.9em;
        }
    }

    .sidemenu-small ul li a{
        width:0px;
        padding: 0.8em 0em 0.8em 2.9em;
    }
    .sidemenu-animate ul li a{
        animation: sidemenu_a_smaller 0.5s ease-in;
    }
}
@media only screen and (max-width: 39em) {
    .sidemenu{
        width:250px;
        height: 100vh;
        position: fixed;
        top: 2px;
        right:0;
        display:block;
        box-sizing: border-box;
        padding: 1.5em 0 0 0;
        background:#f8f8f8;
        box-shadow: 0 3px 10px rgba(0,0,0,.1);
        border-right: 1px solid rgba(0,0,0,0.15);
        transition: transform 0.7s;
        overflow: hidden;
        transform: translate(250px, 0);
        z-index: 10000 !important;
    }
    .opened{
        transform: translate(0,0) !important;
    }
    .shadow{
        position: fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 9000 !important;
        background: rgba(0,0,0,0.3);
        display: none;
    }
    .shadow-show{
        display: block;
        animation: shadow-show 0.5s;
    }
    .shadow-hide{
        display: none;
        animation: shadow-hide 0.5s;
    }
}
@keyframes shadow-show {
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
}
@keyframes shadow-hide {
    0%{
        opacity: 1;
    }
    100%{
        display: block;
        opacity: 0;
    }
}
@media only screen and (max-height: 474px) {
    .sidemenu{
        position: absolute !important;
        top:0;
        min-height: 475px;
    }
}
.sidemenu ul{
    width:100%;
    list-style-type: none;
}
.sidemenu ul .delimiter{
    display:block;
    position: relative;
    width:100%;
    height:1px;
    background: rgba(0,0,0,0);
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
.sidemenu li{
    width:100%;
    display:block;
    height:auto;
    min-height: 1em;
    border-bottom: 0px solid red;
    padding: 0.5em 1em;
    box-sizing: border-box;
}
.sidemenu li a{
    width:100%;
    height: auto;
    min-height: 2em;
    padding: 0.8em 1em 0.8em 3.5em;
    background-color: rgba(0,0,0,0.0);
    text-decoration: none;
    display:block;
    box-sizing: border-box;
    color:rgba(0,0,0,0.7);
    border-radius:10px;
    border:1px solid rgba(0,0,0,0);
    transition: all 0.3s;
    position: relative;
    font-size: 1em;
    font-weight: 400;
    font-family: "Roboto", Verdana, Geneva, Tahoma, sans-serif;
    overflow: hidden;
}
.sidemenu li .active{
    background-color: #dde0e2;
    color:#000;
}
.sidemenu li .active:hover{
    background-color: #dde0e2;
    color:#202020;
    border:1px solid rgba(0,0,0,0.5);
}
.sidemenu li a:hover{
    background-color: rgba(0,0,0,0.02);
    color:#202020;
    border:1px solid rgba(0,0,0,0.5);
}
.sidemenu li a:after{
    content:'';
    position: absolute;
    left:0.5em;
    top:0.35em;
    display:block;
    border-radius: 50%;
    width:1.9em;
    height:1.9em;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.1);
    background-repeat: no-repeat;
    background-size: 1em;
    background-position: center center;
    box-shadow: 0 0 3px rgba(0,0,0,0);
}
.sidemenu li .loading:after{
    background-image: url("../../img/loader-circle-black.svg") !important;
}
.sidemenu li .serverlets-icon:after{
    background-image: url("../../img/server.svg");
}
.sidemenu li .mailets-icon:after{
    background-image: url("../../img/mailet.svg");
}
.sidemenu li .dashboard-icon:after{
    background-image: url("../../img/dashboard.svg");
}
.sidemenu li .domains-icon:after{
    background-image: url("../../img/internet.svg");
}
.sidemenu li .billing-icon:after{
    background-image: url("../../img/invoice.svg");
}
.sidemenu li .settings-icon:after{
    background-image: url("../../img/settings.svg");
}
.sidemenu li .manual-icon:after{
    background-image: url("../../img/manual.svg");
}
.sidemenu li .settings-icon:after{
    background-image: url("../../img/settings.svg");
}
.sidemenu li .logout-icon:after{
    background-image: url("../../img/logout.svg");
}
.sidemenu li .support-icon:after{
    background-image: url("../../img/lifebuoy.svg");
}