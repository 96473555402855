.content .section-half .step,
.content .section .step{
    position: absolute;
    left:0;
    top:0;
    font-weight: 400;
    font-size: 1em;
    display:inline-block;
    background:rgba(0,0,0,0.1);
    border-radius: 6px;
    overflow: hidden;
    padding: 0.25em 0.5em;
    box-sizing: border-box;
}

.section .option .info{
    position: absolute;
    bottom:-25px;
    left:50%;
    transform: translate(-50%,0);
    font-size: 0.7em;
    display:inline-block;
    color:rgba(192, 57, 43,1.0);
    white-space: nowrap;
    box-sizing: border-box;
    padding:3px 10px;
    border-radius: 10px;
    font-weight: 600;
}