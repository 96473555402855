@media only screen and (min-width: 40em) {
    .horizontal_menu{
        width:calc(100% - 250px);
        margin: 0 0 0 250px;
        padding:1em 2em 0 2em;
    }
}
@media only screen and (max-width: 39em) {
    .horizontal_menu{
        width:100%;
        margin: 0;
        padding:1em 0.5em 0 0.5em;
    }
}
.horizontal_menu{
    display:block;
    border-bottom:1px solid rgba(0,0,0,0.1);
    border-right:1px solid rgba(0,0,0,0.15);
    box-shadow: inset 0 -10px 10px -10px rgba(0,0,0,0.05);
    box-sizing: border-box;
}
.horizontal_menu ul{
    width:100%;
    list-style-type: none;
    text-align: center;
}
@media only screen and (min-width: 40em) {
    .horizontal_menu ul li{
        padding: 0 1em 0 1em;
    }
    .horizontal_menu ul li a{
        padding: 0.8em 1em 1.5em 3.5em;
        font-size: 1em;
    }
}
@media only screen and (max-width: 39em) {
    .horizontal_menu ul li{
        padding: 0 0.5em 0 0.5em;
    }
    .horizontal_menu ul li a{
        padding: 0.8em 1em 1.5em 3.5em;
        font-size: 0.9em;
    }
}
.horizontal_menu ul li{
    display:inline-block;
    border-bottom: 0px solid red;
    box-sizing: border-box;
}
.horizontal_menu ul li a{
    display:inline-block;
    box-sizing: border-box;
    color:rgba(0,0,0,0.6);
    border-bottom:3px solid transparent;
    font-weight: 400;
    text-decoration: none;
    position: relative;
}
.horizontal_menu ul li .active{
    color:#000;
    border-bottom:3px solid #fbc531;
}
.horizontal_menu ul li .active:hover{
    color:#202020;
}
.horizontal_menu ul li a:hover{
    color:#202020;
    border-bottom:3px solid rgba(0,0,0,0.3);
}

.horizontal_menu ul li a:after{
    content:'';
    position: absolute;
    left:0.5em;
    top:0.35em;
    display:block;
    border-radius: 50%;
    width:1.9em;
    height:1.9em;
    box-sizing: border-box;
    background-color: rgba(0,0,0,0.1);
    background-repeat: no-repeat;
    background-size: 1em;
    background-position: center center;
    box-shadow: 0 0 3px rgba(0,0,0,0);
}
.horizontal_menu ul li .orders-icon:after{
    background-image: url("../../img/order.svg");
}
.horizontal_menu ul li .credit-history-icon:after{
    background-image: url("../../img/credit-history.svg");
}

.horizontal_menu ul li .domains-icon:after{
    background-image: url("../../img/internet.svg");
}
.horizontal_menu ul li .domains-dns-icon:after{
    background-image: url("../../img/dns_icon_black.svg");
}