.domain_dns_content .section-half .inner-frame,
.domain_dns_content .section .inner-frame{
    margin: 1em 0 0 0;
    border-radius: 10px;
    background: rgba(255,255,255,1);
    box-shadow: 0 0 5px rgba(0,0,0,0.06);
    box-sizing: border-box;
    width: 100%;
    min-height: 2em;
    display: block;
    overflow: hidden;
    border: 1px solid rgba(0,0,0,0.1) ;
    font-size: 1.05em;
    padding: 0 !important;
}
.domain_dns_content .section-half .inner-frame table,
.domain_dns_content .section .inner-frame table{
    position: relative;
    width:100%;
    text-align: left;
}
.domain_dns_content .section-half .inner-frame table .edit,
.domain_dns_content .section .inner-frame table .edit,
.domain_dns_content .section-half .inner-frame table .delete,
.domain_dns_content .section .inner-frame table .delete
{
    position: relative !important;
    text-align: right;
    overflow: hidden;
    box-sizing: border-box;
}
.domain_dns_content .section .inner-frame table .edit {
    width:40px;
}
.domain_dns_content .section .inner-frame table .delete {
    width:10px !important;
}
.domain_dns_content .section table .type{
    position: relative !important;
    width:30px !important;
    text-align: left;
    padding: 0.6em 1em 0.6em 1.75em !important;
}
.domain_dns_content .section table .name{
    position: relative !important;
    width:140px !important;
    text-align: left;
    padding: 0.6em 1em 0.6em 1.75em !important;
    overflow: hidden;
}
.domain_dns_content .section table .value{
    max-width: 200px !important;
    word-break: break-word;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.domain_dns_content .section table .ttl{
    position: relative !important;
    width:100px !important;
    text-align: left;
    padding: 0.6em 1em 0.6em 1.75em !important;
    overflow: hidden;
}
.domain_dns_content .section table .span-priority{
    position: relative;
    width:auto;
    display: inline-block;
    font-size: 0.9em;
    border:1px solid #2bc48a;
    padding: 0.3em 0.4em;
    border-radius: 8px;
    background: rgba(74, 210, 149, 0.15);
    color:#29a66e;
    font-weight: 400;
    margin: -0.3em 0.5em -0.3em 0;
}
.domain_dns_content .section table .span-ttl{
    position: relative;
    width:auto;
    display: inline-block;
    font-size: 0.9em;
    border:1px solid #fbc531;
    padding: 0.3em 0.4em;
    border-radius: 8px;
    background: rgba(251, 197, 49, 0.15);
    color:#dba204;
    font-weight: 400;
    margin: -0.3em 0.5em -0.3em 0;
}
.domain_dns_content .section table td{
    border-right: none !important;
    font-size: 0.9em !important;
    padding: 0.75em 1.75em;
}
.domain_dns_content .section table td:first-child{
    padding: 0.75em 1.75em 0.75em 1.25em !important;
}
.domain_dns_content .section table tr{
    border-bottom:1px solid rgba(0,0,0,0.1) !important;
    font-weight: 400;
    font-size: 0.95em;
    color:rgba(0,0,0,1);
    padding: 0.6em 1.75em !important;
}
.domain_dns_content .section table tr:hover{
    background:rgba(0,0,0,0.05);
}

.domain-dns-input .dns-type,
.domain-dns-input .dns-name,
.domain-dns-input .dns-name-large,
.domain-dns-input .dns-value,
.domain-dns-input .dns-value-small,
.domain-dns-input .dns-ttl,
.domain-dns-input .dns-priority
{
    position: relative;
    display:inline-block;
    height: 52px;
    border:none;
    background: transparent;
    box-sizing: border-box;
    font-size: 15px !important;
    font-family: "Roboto", Verdana, Geneva, Tahoma, sans-serif;
}

.domain-dns-input .dns-textarea
{
    position: relative;
    display:inline-block !important;
    height: 72px;
    min-height: 72px;
    max-height: 400px;
    background: transparent;
    box-sizing: border-box;
    font-size: 15px !important;
    width:100%;
    min-width: 100%;
    max-width: 100%;
    border-radius: 8px;
    border:1px solid rgba(0,0,0,0.3);
    padding: 10px 15px;
    font-family: "Roboto", Verdana, Geneva, Tahoma, sans-serif;
    color: #202020;
    outline: none;
    margin: 1em 0 0 0 !important;
}
.domain-dns-input .dns-textarea:focus
{
    background: #fff;
    box-shadow: 0 0 2px #fbc531 !important;
    border: 1px solid #fbc531 !important;
}
.domain-dns-input .dns-type{
    width:100px;
    border-radius: 8px 0 0 8px;
    border:1px solid rgba(0,0,0,0.3);
    border-right: none;
    padding: 5px 12px;
    color: #202020;
}
.domain-dns-input .dns-type:focus{
    background: #fff;
    box-shadow: 0 0 2px #fbc531 !important;
    border: 1px solid #fbc531 !important;
    border-right: none;
}
.domain-dns-input .dns-name{
    width:180px !important;
    border-radius: 0px !important;
    border-right: none !important;
}
.domain-dns-input .dns-name-large{
    width:calc(100% - 255px) !important;
    border-radius: 0px !important;
    border-right: none !important;
}
.domain-dns-input .dns-value-none,
.domain-dns-input .dns-priority-none{
    display:none !important;
}
.domain-dns-input .dns-priority{
    width:70px !important;
    border-radius: 0px !important;
    border:1px solid rgba(0,0,0,0.3);
    border-right: none !important;
    padding: 5px 0px 5px 8px !important;
    color: #202020;
}
.domain-dns-input .dns-value{
    position: relative;
    width:calc(100% - 435px) !important;
    border-radius: 0px !important;
    border-right: none !important;
}
.domain-dns-input .dns-value-small{
    position: relative;
    width:calc(100% - 505px) !important;
    border-radius: 0px !important;
    border-right: none !important;
}
.domain-dns-input .dns-ttl{
    width:75px;
    border-radius: 0px !important;
    border:1px solid rgba(0,0,0,0.3);
    padding: 5px 5px;
    color: #202020;
}
.domain-dns-input .dns-ttl:focus{
    background: #fff;
    box-shadow: 0 0 2px #fbc531 !important;
    border: 1px solid #fbc531 !important;
}
.domain-dns-input .list-text:focus ~ .list-submit{
    background: #fbc531 !important;
    border:1px solid #fbc531 !important;
    border-left:none !important;
}
.domain-dns-input .list-submit{
    position: relative;
    display:inline-block !important;
    width:80px !important;
    height:52px !important;
    border-radius: 0 8px 8px 0 !important;
    box-sizing: border-box !important;
    top:0px !important;
    border:1px solid rgba(0,0,0,0.3) !important;
    border-left:none !important;
    padding: 5px 12px;
}
.domain-dns-input .list-submit:hover{
    background: #fbc531 !important;
    border:1px solid #fbc531 !important;
    border-left:none !important;
}
.domain-dns-input .list-submit:focus{
    border:1px solid rgba(0,0,0,0.3) !important;
    border-left:none !important;
    background: rgba(0,0,0,0.1) !important;
}
.domain-dns-input .dns-type-label,
.domain-dns-input .dns-name-label,
.domain-dns-input .dns-value-label,
.domain-dns-input .dns-ttl-label
{
    position: relative;
    display:inline-block;
    font-size: 0.7em;
    color:rgba(0,0,0,0.5);
    top:-0.5em;
}

.domain-dns-input .dns-type-label {
    left:15px;
}
.domain-dns-input .dns-name-label {
    left:50px;
}
.domain-dns-input .dns-value-label {
    left:125px;
}
.domain-dns-input .dns-ttl-label {
    left:calc((100% - 425px) + 17px);
}

.domain_dns_content .edit-button{
    position: absolute;
    height:2em;
    width:2em;
    display:inline-block;
    padding: 0.15em 0.7em;
    right:0;
    top:0.3em;
    outline:none;
    border-radius: 0.5em;
    border:1px solid rgba(0,0,0,0.3);
    background: transparent url("../img/edit.svg") center center no-repeat;
    background-size: 0.75em;
    cursor: pointer;
    margin: 0;
    color:rgba(0,0,0,0.75);
}
.domain_dns_content .edit-button:hover{
    border:1px solid #fbc531;
    background: #fbc531 url("../img/edit.svg") center center no-repeat;
    background-size: 0.75em;
}
.domain_dns_content .delete-button{
    position: absolute;
    height:2em;
    width:2em;
    display:inline-block;
    padding: 0.15em 0.7em;
    right:1.25em;
    top:0.3em;
    outline:none;
    border-radius: 0.5em;
    border:1px solid #c0392b;
    background: transparent url("../img/delete.svg") center center no-repeat;
    background-size: 1em;
    cursor: pointer;
    color:#c0392b;
    z-index:100;
}
.domain_dns_content .delete-button:hover{
    background: #c0392b url("../img/delete-white.svg") center center no-repeat;
    background-size: 1em;
    border:1px solid #c0392b;
    color:#fff;
}